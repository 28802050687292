import React from "react";

export const Education4 = () => {
  return (
    <div>
      <section class="breadcrumb-outer text-center">
        <div class="container">
          <div class="breadcrumb-content">
            <h2 style={{ color: "black" }}>Explore</h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
        </div>
        <div class="overlay"></div>
      </section>

      <section class="flight-destinations bg-white">
        <div class="container">
          <div class="row">
            <div id="content" class="col-md-8 col-xs-12">
              <div class="detail-content content-wrapper">
                <div class="detail-info">
                  <div class="detail-info-content clearfix">
                    <h4 class="mar-0">Go Crazy in China Holiday Package</h4>
                    <h2>China </h2>
                    <div class="rating">
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                    </div>
                    {/* <h3 class=" detail-price mar-0 text-right">
                      $659 <br />
                      Person
                    </h3> */}
                  </div>
                </div>
                <div class="gallery detail-box about-slider">
                  <div class="gallery-item">
                    <img src="images/China.webp" alt="in_th_030_01_sm" />
                  </div>
                </div>
                <div class="description flight-table mar-bottom-30">
                  <div class="detail-title">
                    <h3>Itinerary</h3>
                  </div>
                  <div class="description mar-bottom-30">
                    <ul class="expect">
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 1:</b> On the first day, fly to China and reach
                        the Beijing Capital Int'l Airport. You will be received
                        by our English-talking airport agent at the airport.
                        Just relocate to lodging and take a rest. Day is left
                        for your leisure time.
                        <br /> Option: Enjoy the evening Acrobatic show <br />
                        No meal
                      </li>
                      <li>
                        <i class="fa fa-circle"></i> <b>Day 2:</b>After your
                        morning breakfast, take a flight and start your city
                        visit. Proceed to the Summer Palace, which is the
                        biggest and best protected of all the supreme castle
                        enclosures. Move on to the "Marble Boat" and the
                        lavishly painted" Long Corridor", Pearl shop. A visit to
                        the world celebrated Tian'anmen Square would enable you
                        to explore the greatest square on the planet. Visit the
                        Forbidden City from which the Emperors of the Ming and
                        Qing traditions represented in excess of 500 years.
                        Alternative: Hutong visit, which is likewise called
                        Rickshaw Tour. <br />
                        Visit Houhai, is a lake and its encompassing locale in
                        focal Beijing, one of the three sections of Shichahai.
                        It is famous to numerous famous restaurants, bars, and
                        cafes. The zone is particularly main stream with remote
                        vacationers going by Beijing, yet is likewise frequently
                        gone by the exile group and more youthful local people.
                        Choice: Kongfu indicate at night. 2030p.m. Exchange to
                        Indian restaurant for supper and after that return back
                        lodging. Breakfast + Indian Dinner
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 3:</b> Take your morning breakfast and catch a
                        flight to being the city tour. Visit the mythical Great
                        Wall-Juyong Pass, an UNESCO World Heritage Site and a
                        monstrous guarding structure manufactured to keep off
                        interruption. Visit a Longdi Jade shop where you will
                        look into Chinese jade society. Choice system: Ming
                        Tombs Dingling. In the evening, proceed to see the
                        Bird's Nest (Olympic National Stadium outside) and the
                        Water Cube (Outside). You can enjoy your shopping at
                        Yaxiu business sector. 2030p.m. Exchange to Indian
                        restaurant for supper and after that return back inn.
                        Breakfast + Chinese Lunch in Great Wall+ Indian supper.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i> <b>Day 4:</b>In the
                        morning, fly to Shanghai. If hotel has packed breakfast,
                        we will ask for visitor to take with them. Our tour
                        guide will transfer you to the airport. After your
                        arrival, you will be picked up by out guide and
                        transferred to the hotel. Breakfast at lodging, Later,
                        move out from the hotel and start you city tour. Proceed
                        to the Jade Buddha Temple, the current sanctuary draws
                        from both the Pure Land and Chan conventions of Mahayana
                        Buddhism. Visit the People Square and Nanjing Road for
                        shopping, Option project: Shanghai Madame Tussauds wax
                        historical center roaming in Riverside Promenade Taobao
                        City for shopping 20.00p.m. Exchange to Indian
                        restaurant for supper and after that return back inn.
                        Breakfast+ Indian supper.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i> <b>Day 5:</b>After
                        breakfast at lodging, visit the Shanghai Museum which is
                        the biggest historical center of old Chinese
                        craftsmanship in Shanghai. Proceed to the dazzling old
                        Yuyuan Garden-outside with brilliant fish lake,
                        extensions and structures. Step onto Yu Market (Old City
                        God's Temple), which is a major yet moderately latent,
                        Taoist sanctuary in Shanghai. Visit the Oriental Pearl
                        TV tower second level Option programs: Shanghai Aquarium
                        after 1600 p.m. Choice project: Huangpu River Cruise
                        2000pm Transfer to Indian restaurant for supper and
                        afterward return back to inn. Breakfast + Indian supper.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 6:</b> Take a flight and move on to Xitang on
                        time. Xitang is level, thickly conveyed with waterways
                        and has a calm nature.. Xitang is a water town jumbled
                        by nine streams. The town extends over eight segments,
                        joined by antiquated stone extensions. In the more
                        established parts of town, the structures are situated
                        along the banks of the trenches, which serve as the
                        primary transportation lanes in the region. Xitang
                        contains various obsolescent living arrangements and
                        sanctuaries, for example, the Temple of the Seven
                        Masters. <br />
                        The town keeps a peaceful mood and beautiful
                        magnificence, making it an exceptionally well known
                        vacation spot. Through a bird's-eye perspective of the
                        entire town, green waves swell all around and each
                        family dwells close water. Our tour guide will take you
                        for a vessel ride to revel in the lovely landscape. Move
                        back to Shanghai. Get into a Chinese Vegetarian supper
                        at neighbourhood restaurant and enjoy a great meal.
                        Continue your journey towards the Xintiandi which is a
                        wealthy auto free shopping, consuming and stimulation
                        area of Shanghai, China. <br />
                        It is made out of a range of reconstituted conventional
                        shikumen ("stone entryway") houses on thin rear ways,
                        some adjoining houses which now serve as book shops,
                        bistros and restaurants, and shopping centers. The
                        majority of the bistros and restaurants characteristic
                        both indoor and open air seatings. Xintiandi has a
                        dynamic nightlife on weekdays and weekends, however
                        sentimental settings are more basic than boisterous
                        music and move places. It is viewed as one of the first
                        lifestyle focuses in China. Xintiandi is the area of the
                        site of the first congress of and the Communist Party of
                        China, now safeguarded at the Museum of the First
                        National Congress of the Chinese Communist Party. Get
                        back to the hotel for breakfast + Indian supper.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 7:</b> After morning breakfast, set for a
                        prologue voyage through Zurich which blankets Old Town,
                        Fraumuenster Church with its popular Chagall windows,
                        authentic Limmatquai and the college area. Drive along
                        the Hoehenstrasse which offers breathtaking perspectives
                        into the Alps, we then drive to Rapperswil which is
                        named the 'City of Roses'. After lunch set to half day
                        visit to Rhine falls, Located close Schaffhausen, the
                        lofty Rhine River streams into the amazing structure of
                        the Rhine Falls, With its gigantic 50 meters in width
                        and arriving at a stature of 23 meters, a torrential
                        700m3 of spouting water surged over the precipice every
                        second offering the guests a shocking perspective of the
                        falling waterfalls. Supper and night stay at Zurich
                        hotel.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 8:</b> After a quick morning breakfast, depart to
                        airport to catch Int'l flight.
                        <br /> Choice: You can also get into Maglev Train on the
                        Pudong Airport.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div id="sidebar" class="col-md-4 col-xs-12">
              <aside class="detail-sidebar sidebar-wrapper">
                <div class="sidebar-item">
                  <form class="filter-box">
                    <h3 class="white">For Enquiry</h3>
                    <div class="row">
                      <div class="col-xs-12">
                        <div class="form-group">
                          <label class="white">Your Name</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Name"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="white">Your Email</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Email"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="white">Your Phone</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Phone"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-xs-12">
                        <div class="form-group mar-top-15">
                          <a href="#" class="biz-btn">
                            Submit
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="sidebar-item">
                  <div class="detail-title">
                    <h3>Most Visited</h3>
                  </div>
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/china11.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/china12.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/china13.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/china14.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/china15.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/china16.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
