import React from "react";
import { AboutChoose } from "../components/home/AboutChoose";
import NewBanner from "../components/home/NewBanner";
import Banner from "../components/home/Banner";
import { HolidayPlan } from "../components/home/HolidayPlan";
import { Offer } from "../components/home/Offer";
import { RecentActivity } from "../components/home/RecentActivity";
import { TopDeals } from "../components/home/TopDeals";
import { TopMostDestinations } from "../components/home/TopMostDestinations";
import { VideoSection } from "../components/home/VideoSection";
import { WhyChoose } from "../components/home/WhyChoose";

const Home = () => {
  return (
    <>
      <Banner />
      {/* <NewBanner /> */}
      <AboutChoose />
      <TopMostDestinations />
      <HolidayPlan />
      {/* <VideoSection /> */}
      <TopDeals />
      {/* <Offer /> */}
      {/* <RecentActivity /> */}
      <WhyChoose />
    </>
  );
};

export default Home;
