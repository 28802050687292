import React from "react";

export const Education7 = () => {
  return (
    <div>
      <section class="breadcrumb-outer text-center">
        <div class="container">
          <div class="breadcrumb-content">
            <h2 style={{ color: "black" }}>Explore</h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
        </div>
        <div class="overlay"></div>
      </section>

      <section class="flight-destinations bg-white">
        <div class="container">
          <div class="row">
            <div id="content" class="col-md-8 col-xs-12">
              <div class="detail-content content-wrapper">
                <div class="detail-info">
                  <div class="detail-info-content clearfix">
                    <h4 class="mar-0">Best Views of Switzerland</h4>
                    <h2>Switzerland</h2>
                    <div class="rating">
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                    </div>
                    {/* <h3 class=" detail-price mar-0 text-right">
                      $659 <br />
                      Person
                    </h3> */}
                  </div>
                </div>
                <div class="gallery detail-box about-slider">
                  <div class="gallery-item">
                    <img src="images/Switzerland.webp" alt="in_th_030_01_sm" />
                  </div>
                </div>
                <div class="description flight-table mar-bottom-30">
                  <div class="detail-title">
                    <h3>Itinerary</h3>
                  </div>
                  <div class="description mar-bottom-30">
                    <ul class="expect">
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 1:</b> After your arrival at Paris, you will be
                        received and transferred to lodging. After a delicious
                        lunch visit Eiffel Tower level 3. Relax your day in
                        exploring Paris on guided visit. Spend your overnight
                        stay at the inn.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i> <b>Day 2:</b>After morning
                        breakfast, relax at hotel. In the evening, you will be
                        taken to an esteemed inhabited area near the Bois de
                        Boulogne prior to enjoying a one-hour glide on the River
                        Seine. Return back for overnight stay to the hotel.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 3:</b> After morning breakfast, sign-out the
                        hotel and relocate to Basel by euro rail. On arrival at
                        Basel, check-in the hotel and after lunch continue for a
                        guided tour of Basel. Enjoy your night stay in the
                        hotel.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i> <b>Day 4:</b>Take your
                        morning breakfast and move to Lucerne. Visit the vital
                        spots like Jesuit church, Lucerne sanctuary span, Art
                        gallery, Mill Bridge, Picasso exhibition hall, zyt
                        tower. Late nighttime revels in the beautiful Lake
                        Lucerne in watercraft. Enjoy the late night delights in
                        a luxurious Indian supper and night stay at Lucerne.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i> <b>Day 5:</b>After a
                        healthy breakfast, proceed to Interlaken for an
                        introduction voyage through Interlaken. Interlaken, a
                        beguiling city settled between two lakes and encompassed
                        by the stunning Bernese Oberland Mountains Visit swiss
                        Open-Air Museum at Ballenberg, close Brienz. On the
                        other hand get a steamer to Thun, where Lake Thun
                        encourages into the River Aare, and delight in the
                        perspectives from the towers of its medieval château.
                        Later, return once again to inn for dinner and overnight
                        stay at lodging or full day invested time to Jungfrau
                        top of the Europe.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 6:</b> After morning breakfast, sign out of the
                        hotel and set for journey to Mt. Titlis-at 3020m. Board
                        a gondola which takes you to Trubsee. Proceed with your
                        outing by an alternate gondola to stand lastly the first
                        rotating Titlis Rotair link auto takes you up to Mt.
                        Titlis. After lunch, leave for Zurich and sign in the
                        hotel. Day is free for relaxation and night stay at
                        hotel.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 7:</b> After morning breakfast, set for a
                        prologue voyage through Zurich which blankets Old Town,
                        Fraumuenster Church with its popular Chagall windows,
                        authentic Limmatquai and the college area. Drive along
                        the Hoehenstrasse which offers breathtaking perspectives
                        into the Alps, we then drive to Rapperswil which is
                        named the 'City of Roses'. After lunch set to half day
                        visit to Rhine falls, Located close Schaffhausen, the
                        lofty Rhine River streams into the amazing structure of
                        the Rhine Falls, With its gigantic 50 meters in width
                        and arriving at a stature of 23 meters, a torrential
                        700m3 of spouting water surged over the precipice every
                        second offering the guests a shocking perspective of the
                        falling waterfalls. Supper and night stay at Zurich
                        hotel.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 8:</b> After morning breakfast, set for a
                        prologue voyage through Zurich which blankets Old Town,
                        Fraumuenster Church with its popular Chagall windows,
                        authentic Limmatquai and the college area. Drive along
                        the Hoehenstrasse which offers breathtaking perspectives
                        into the Alps, we then drive to Rapperswil which is
                        named the 'City of Roses'. After lunch set to half day
                        visit to Rhine falls, Located close Schaffhausen, the
                        lofty Rhine River streams into the amazing structure of
                        the Rhine Falls, With its gigantic 50 meters in width
                        and arriving at a stature of 23 meters, a torrential
                        700m3 of spouting water surged over the precipice every
                        second offering the guests a shocking perspective of the
                        falling waterfalls. Supper and night stay at Zurich
                        hotel.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div id="sidebar" class="col-md-4 col-xs-12">
              <aside class="detail-sidebar sidebar-wrapper">
                <div class="sidebar-item">
                  <form class="filter-box">
                    <h3 class="white">For Enquiry</h3>
                    <div class="row">
                      <div class="col-xs-12">
                        <div class="form-group">
                          <label class="white">Your Name</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Name"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="white">Your Email</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Email"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="white">Your Phone</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Phone"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-xs-12">
                        <div class="form-group mar-top-15">
                          <a href="#" class="biz-btn">
                            Submit
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="sidebar-item">
                  <div class="detail-title">
                    <h3>Most Visited</h3>
                  </div>
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/sw1 (1).jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/sw1 (2).jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/sw1 (3).jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
