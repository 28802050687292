import React from "react";
import emailjs from "emailjs-com";
import swal from "sweetalert";
export const OfferPage6 = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "template_58obn2m",
        e.target,
        "user_aKCieJwSXAcLTXl3NIl4R"
      )
      .then(
        (result) => {
          swal({
            title: "Done!",
            text: "We will get back to you shortly!",
            icon: "success",
            timer: 3000,
            button: false,
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <div>
      <section class="list">
        <div class="container">
          <div class="row">
            <div class="col-md-8 pad-left-30 pull-right">
              <div class="blog-list">
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/dubai1.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>01</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">Dubai</a>
                        </h3>

                        <p>
                          Check In at the Canvas Hotel with some Honeymoon
                          Special Amenties.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">Midst of the Sea</a>
                        </h3>

                        <p>
                          Breakfast at the Hotel In Dubai Day Free at the Hotel.
                          Enjoy the evening sunset in the midst of the sea with
                          01Hr.Yacht Ride with a bottle of wine.
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/dubai2.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>02</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/dubai3.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>03</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">Dubai Dessert Safari</a>
                        </h3>

                        <p>
                          Breakfast at the Hotel in Dubai Afternoon proceed for
                          Dubai Dessert Safari at AI Hadeerah Camp of 5 star Bab
                          AI Shams Dessert Resort & Spa
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">Canvas Hotel</a>
                        </h3>

                        <p>
                          Breakfast at the Hotel.01.Hour Limo Ride Followed by
                          Evening Dinner offered by the Canvas Hotel Dubai at
                          Signature by Sanheev Kapoor Restaurant
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/dubai4.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>04</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/dubai5.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>05</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="blog-single.html">Canvas Hotel</a>
                        </h3>

                        <p>
                          Breakfast at the hotel followed by Check out. Later
                          Departure Transfer to Dubai International Airport on
                          Lexus with a Memorable Trip to cherish for the rest of
                          your life.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-xs-12">
              <div class="list-sidebar">
                <div class="sidebar-item">
                  <form class="filter-box" onSubmit={sendEmail}>
                    <h3 class="white">Enquire This Package</h3>
                    <div class="row">
                      <div class="col-xs-12">
                        <div class="form-group">
                          <label class="white">Your Name</label>
                          <div class="input-box">
                            <i class="flaticon-user"></i>
                            <input
                              type="text"
                              placeholder="Name"
                              style={{ paddingRight: "6px" }}
                              name="name"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label class="white">Check In</label>
                          <div class="input-box">
                            <i class="flaticon-calendar"></i>
                            <input
                              id="date-range0"
                              type="text"
                              placeholder="yyyy-mmm-dd"
                              name="datein"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label class="white">Check Out</label>
                          <div class="input-box">
                            <i class="flaticon-calendar"></i>
                            <input
                              id="date-range1"
                              type="text"
                              placeholder="yyyy-mm-dd"
                              name="dateout"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label class="white">Adult</label>
                          <div class="input-box">
                            <i class="flaticon-add-user"></i>
                            <select class="niceSelect">
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label class="white">Children</label>
                          <div class="input-box">
                            <i class="flaticon-add-user"></i>
                            <select class="niceSelect">
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="comment-btn text-right mar-top-15">
                        <input
                          type="submit"
                          class="biz-btn"
                          id="submit"
                          value="Enquire Now"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div class="sidebar-item">
                  <h3>Services</h3>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>24/7 Reception</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Parking</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Bar</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Restaurant</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Satellite Television</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Lift/ELevator</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Luggage Storage </label>
                    </div>
                  </div>
                </div>

                <div class="sidebar-item">
                  <h3>Star Rating</h3>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        <div class="star-rating">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        <div class="star-rating">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        <div class="star-rating">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        <div class="star-rating">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        <div class="star-rating">
                          <span class="fa fa-star checked"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="sidebar-item">
                  <h3>City</h3>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        Amsterdam<span class="number">749</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" checked />
                    <div class="state">
                      <label>
                        Rotterdam<span class="number">630</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        Copenghan<span class="number">58</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        New Delhi<span class="number">29</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        New York<span class="number">29</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        Kathmandu<span class="number">29</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        Brisbane<span class="number">29</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        Tokyo<span class="number">29</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
