import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import "./main.min.css";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("flights__world")
);
