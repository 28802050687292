import { HashRouter, Route, Switch } from "react-router-dom";
import { Country1 } from "./Country/Country1";
import { Country2 } from "./Country/Country2";
import { Country3 } from "./Country/Country3";
import { Country4 } from "./Country/Country4";
import { Country5 } from "./Country/Country5";
import { Country6 } from "./Country/Country6";
import { Country7 } from "./Country/Country7";
import { Education1 } from "./EducationTour/Education1";
import { Education2 } from "./EducationTour/Education2";
import { Education3 } from "./EducationTour/Education3";
import { Education4 } from "./EducationTour/Education4";
import { Education5 } from "./EducationTour/Education5";
import { Education6 } from "./EducationTour/Education6";
import { Education7 } from "./EducationTour/Education7";
import { Footer } from "./layouts/Footer";
import Header from "./layouts/Header";
import AboutUs from "./pages/AboutUs";
import { ContactUs } from "./pages/ContactUs";
import { Faq } from "./pages/Faq";

// Pages & Layouts
import Home from "./pages/Home";
import { NotFound } from "./pages/NotFound";
import { OfferPage1 } from "./pages/OfferPage1";
import { OfferPage2 } from "./pages/OfferPage2";
import { OfferPage3 } from "./pages/OfferPage3";
import { OfferPage4 } from "./pages/OfferPage4";
import { OfferPage5 } from "./pages/OfferPage5";
import { OfferPage6 } from "./pages/OfferPage6";
import { Privacy } from "./pages/Privacy";
import { StudyAbrod } from "./pages/StudyAbrod";
import { Terms } from "./pages/Terms";
import ScrollToTop from "./ScrollToTop";
import Popup from "./layouts/Popup";

const App = () => {
  return (
    <>
      <Popup />
      <HashRouter>
        <ScrollToTop />
        <Header />

        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/aboutus" component={AboutUs} exact />
          <Route path="/contactus" component={ContactUs} exact />
          <Route path="/studyabroad" component={StudyAbrod} exact />
          <Route path="/countryone" component={Country1} exact />
          <Route path="/countrytwo" component={Country2} exact />
          <Route path="/countrythree" component={Country3} exact />
          <Route path="/countryfour" component={Country4} exact />
          <Route path="/countryfive" component={Country5} exact />
          <Route path="/countrysix" component={Country6} exact />
          <Route path="/countryseven" component={Country7} exact />
          <Route path="/offerpage1" component={OfferPage1} exact />
          <Route path="/offerpage2" component={OfferPage2} exact />
          <Route path="/offerpage3" component={OfferPage3} exact />
          <Route path="/offerpage4" component={OfferPage4} exact />
          <Route path="/offerpage5" component={OfferPage5} exact />
          <Route path="/offerpage6" component={OfferPage6} exact />
          <Route path="/education1" component={Education1} exact />
          <Route path="/education2" component={Education2} exact />
          <Route path="/education3" component={Education3} exact />
          <Route path="/education4" component={Education4} exact />
          <Route path="/education5" component={Education5} exact />
          <Route path="/education6" component={Education6} exact />
          <Route path="/education7" component={Education7} exact />
          <Route path="/terms" component={Terms} exact />
          <Route path="/faq" component={Faq} exact />
          <Route path="/privacy" component={Privacy} exact />
          <Route component={NotFound} exact />
        </Switch>

        <Footer />
      </HashRouter>
    </>
  );
};

export default App;
