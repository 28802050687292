import React from "react";
import modalImg from "../assets/images/modal.jpg";

const Popup = () => {
  const [open, setOpen] = React.useState(true);
  const onModalClose = (e) => setOpen((o) => false);

  return (
    <div className={`modal__wrapper ${open ? "toggle__popup" : ""}`}>
      <div className="modal">
        <div className="modal__close" onClick={(e) => onModalClose(e)}>
          x
        </div>
        <div className="modal__content">
          <img src={modalImg} alt="" />
          <div className="modal__text">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod
              doloremque delectus iusto possimus cumque, voluptate nesciunt
              nihil voluptatum sapiente pariatur odit perferendis ea nulla
              dolorem, explicabo fuga! Eum vluptatibus consequatur blanditiis
              itaque possimus dicta veritatis? Impedit iusto sapiente
              consectetur, maiores vero odit repudiandae neque voluptatum beatae
              praesentium explicabo quo amet facilis numquam natus non molestiae
              culpa earum, ad saepe et veniam eligendi placeat. Ipsam, magni
              voluptatibus asperiores doloremque nam sunt in id minus
              consequatur a voluptas aspernatur blanditiis reprehenderit odit,
              cum accusantium ratione dolorum doloribus! Dolor fugit alias id
              corrupti ut quaerat facere asperiores minus, expedita at,
              doloribus impedit. Minima? Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Quod doloremque delectus iusto possimus cumque,
              voluptate nesciunt nihil voluptatum sapiente pariatur odit
              perferendis ea nulla dolorem, explicabo fuga! Eum voluptatibus
              consequatur blanditiis itaque possimus dicta veritatis? Impedit
              iusto sapiente
            </p>
            <p>
              consectetur, maiores vero odit repudiandae neque voluptatum beatae
              praesentium explicabo quo amet facilis numquam natus non molestiae
              culpa earum, ad saepe et veniam eligendi placeat. Ipsam, magni
              voluptatibus asperiores doloremque nam sunt in id minus
              consequatur a voluptas aspernatur blanditiis reprehenderit odit,
              cum accusantium ratione dolorum doloribus! Dolor fugit alias id
              corrupti ut quaerat facere asperiores minus, expedita at
            </p>
          </div>
          <div className="modal__action">
            <button>SUBSCRIBE</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
