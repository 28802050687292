import React from "react";
import { Slide, Roll } from "react-reveal";
export const WhyChoose = () => {
  return (
    <div>
      <section class="why-us pad-top-0">
        <div class="container">
          <div class="section-title">
            <h2>Why Choose Us</h2>
            <p>
              We provide customized travel services in all directions, from
              Premium luxury hotels to Holiday packages, all of which cover both
              Domestic and International destinations.
            </p>
          </div>
          <div class="why-us-box">
            <div class="row">
              <div class="col-md-4">
                <Roll left>
                  <div class="why-us-item text-center">
                    <div class="why-us-icon">
                      <i class="flaticon-price"></i>
                    </div>
                    <div class="why-us-content">
                      <h3>
                        <a href="#">Competetive Pricing</a>
                      </h3>
                      <p class="mar-0">
                        With 500+ suppliers and the purchasing power of 300
                        million members
                      </p>
                    </div>
                  </div>
                </Roll>
              </div>

              <div class="col-md-4">
                <Roll right>
                  <div class="why-us-item text-center">
                    <div class="why-us-icon">
                      <i class="flaticon-call"></i>
                    </div>
                    <div class="why-us-content">
                      <h3>
                        <a href="#">Service</a>
                      </h3>
                      <p class="mar-0">
                        Fabulous Travel worry free knowing that we're here if
                        you need us, 24 hours a day
                      </p>
                    </div>
                  </div>
                </Roll>
              </div>

              <div class="col-md-4">
                <Roll left>
                  <div class="why-us-item text-center">
                    <div class="why-us-icon">
                      <i class="flaticon-global"></i>
                    </div>
                    <div class="why-us-content">
                      <h3>
                        <a href="#">Worldwide Coverage</a>
                      </h3>
                      <p class="mar-0">
                        1,200,000 hotels in more than 200 countries and regions
                        & flights to over 5,000 citites.
                      </p>
                    </div>
                  </div>
                </Roll>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
