import React from "react";
import { Link } from "react-router-dom";

export const TopMostDestinations = () => {
  return (
    <div>
      <section class="top-desti pad-0" id="flights">
        <div class="desti-inner" id="flights">
          <div class="row">
            <div class="col-md-3 col-sm-6 col-xs-12">
              <img src="images/destination3.webp" alt="desti" />
              <div class="desti-title">
                <div class="desti-title-inner">
                  <h2 class="white bold">
                    Top Most <br />
                    Destination
                  </h2>
                  <p class="white mar-bottom-0">
                    providing quality and assured travel services to customers
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
              <div class="desti-image">
                <img src="images/destination3.webp" alt="desti" />
                <div class="desti-content">
                  <div class="rating mar-bottom-5">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                  </div>
                  <h3 class="white mar-bottom-0">Sri Lanka</h3>
                </div>
                <div class="desti-overlay">
                  <Link to="/countryone" class="biz-btn-white">
                    Explore
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
              <div class="desti-image">
                <img src="images/destination4.webp" alt="desti" />
                <div class="desti-content">
                  <div class="rating mar-bottom-5">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                  </div>
                  <h3 class="white mar-bottom-0">Antoman</h3>
                </div>
                <div class="desti-overlay">
                  <Link to="/countrytwo" class="biz-btn-white">
                    Explore
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
              <div class="desti-image">
                <img src="images/destination5.webp" alt="desti" />
                <div class="desti-content">
                  <div class="rating mar-bottom-5">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                  </div>
                  <h3 class="white mar-bottom-0">Thailand</h3>
                </div>
                <div class="desti-overlay">
                  <Link to="/countrythree" class="biz-btn-white">
                    Explore
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
              <div class="desti-image">
                <img src="images/destination7.webp" alt="desti" />
                <div class="desti-content">
                  <div class="rating mar-bottom-5">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                  </div>
                  <h3 class="white mar-bottom-0">Nepal</h3>
                </div>
                <div class="desti-overlay">
                  <Link to="/countryfour" class="biz-btn-white">
                    Explore
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
              <div class="desti-image">
                <img src="images/destination8.webp" alt="desti" />
                <div class="desti-content">
                  <div class="rating mar-bottom-5">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                  </div>
                  <h3 class="white mar-bottom-0">Maldives</h3>
                </div>
                <div class="desti-overlay">
                  <Link to="/countryfive" class="biz-btn-white">
                    Explore
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
              <div class="desti-image">
                <img src="images/destination9.webp" alt="desti" />
                <div class="desti-content">
                  <div class="rating mar-bottom-5">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                  </div>
                  <h3 class="white mar-bottom-0">Dubai</h3>
                </div>
                <div class="desti-overlay">
                  <Link to="/countrysix" class="biz-btn-white">
                    Explore
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
              <div class="desti-image">
                <img src="images/destination10.webp" alt="desti" />
                <div class="desti-content">
                  <div class="rating mar-bottom-5">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                  </div>
                  <h3 class="white mar-bottom-0">Turkey</h3>
                </div>
                <div class="desti-overlay">
                  <Link to="/countryseven" class="biz-btn-white">
                    Explore
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
