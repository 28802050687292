import React from "react";
import { NavLink } from "react-router-dom";

export const HolidayPlan = () => {
  return (
    <div>
      <section class="trending" id="dealsandoffers">
        <div class="container">
          <div class="section-title">
            <h2>Perfect Holiday Plan</h2>
            <p>
              We’re truely dedicated to make your travel experience as much
              simple and fun as possible.
            </p>
          </div>
          <div class="trend-box">
            <div class="row mix tour">
              <div class="col-md-4 col-sm-6 col-xs-12 mar-bottom-30">
                <div class="trend-item">
                  <div class="ribbon ribbon-top-left">
                    <span>20% OFF</span>
                  </div>
                  <div class="trend-image">
                    <img src="images/Eastern Himalayan.jpg" alt="image" />
                    <div class="trend-tags">
                      <a href="#">
                        <i class="flaticon-like"></i>
                      </a>
                    </div>
                    <div class="trend-price">
                      <p class="price">
                        From <span>₹ 19,999</span>
                      </p>
                    </div>
                  </div>
                  <div class="trend-content">
                    <p>
                      <i class="flaticon-location-pin"></i> Himalaya
                    </p>
                    <h4>
                      <NavLink to="/offerpage1">
                        Eastern Himalayan Package
                      </NavLink>
                    </h4>
                    <div class="rating mar-bottom-10">
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                    </div>
                    <span class="mar-left-5">50 Reviews</span>
                    <p class="mar-0">
                      <i class="fa fa-clock-o" aria-hidden="true"></i> 8 days &
                      7 nights
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12 mar-bottom-30">
                <div class="trend-item">
                  <div class="ribbon ribbon-top-left">
                    <span>15% OFF</span>
                  </div>
                  <div class="trend-image">
                    <img src="images/Andaman.jpg" alt="image" />
                    <div class="trend-tags">
                      <a href="#">
                        <i class="flaticon-like"></i>
                      </a>
                    </div>
                    <div class="trend-price">
                      <p class="price">
                        From <span>₹ 21,999</span>
                      </p>
                    </div>
                  </div>
                  <div class="trend-content">
                    <p>
                      <i class="flaticon-location-pin"></i> Andaman
                    </p>
                    <h4>
                      <NavLink to="/offerpage2">Fabulous Andaman Trip</NavLink>
                    </h4>
                    <div class="rating mar-bottom-10">
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star-half checked"></span>
                      <span class="fa fa-star-half checked"></span>
                    </div>
                    <span class="mar-left-5">48 Reviews</span>
                    <p class="mar-0">
                      <i class="fa fa-clock-o" aria-hidden="true"></i> 5 days &
                      4 nights
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12 mar-bottom-30">
                <div class="trend-item">
                  <div class="ribbon ribbon-top-left">
                    <span>25% OFF</span>
                  </div>
                  <div class="ribbon ribbon-top-left">
                    <span>10% Off</span>
                  </div>
                  <div class="trend-image">
                    <img src="images/Munnar.jpg" alt="image" />
                    <div class="trend-tags">
                      <a href="#">
                        <i class="flaticon-like"></i>
                      </a>
                    </div>
                    <div class="trend-price">
                      <p class="price">
                        From <span>₹ 21,999</span>
                      </p>
                    </div>
                  </div>
                  <div class="trend-content">
                    <p>
                      <i class="flaticon-location-pin"></i> Kerala
                    </p>
                    <h4>
                      <NavLink to="/offerpage3">Fantacy Land Of Kerala</NavLink>
                    </h4>
                    <div class="rating mar-bottom-10">
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                    </div>
                    <span class="mar-left-5">32 Reviews</span>
                    <p class="mar-0">
                      <i class="fa fa-clock-o" aria-hidden="true"></i> 9 days &
                      8 night
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="trend-item">
                  <div class="ribbon ribbon-top-left">
                    <span>28% OFF</span>
                  </div>
                  <div class="trend-image">
                    <img src="images/COORG.jpg" alt="image" />
                    <div class="trend-tags">
                      <a href="#">
                        <i class="flaticon-like"></i>
                      </a>
                    </div>
                    <div class="trend-price">
                      <p class="price">
                        From <span>₹ 26,999</span>
                      </p>
                    </div>
                  </div>
                  <div class="trend-content">
                    <p>
                      <i class="flaticon-location-pin"></i> India
                    </p>
                    <h4>
                      <NavLink to="/offerpage4">
                        Awesome South India Tour
                      </NavLink>
                    </h4>
                    <div class="rating mar-bottom-10">
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star-half checked"></span>
                    </div>
                    <span class="mar-left-5">21 Reviews</span>
                    <p class="mar-0">
                      <i class="fa fa-clock-o" aria-hidden="true"></i> 10 days &
                      9 nights
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="trend-item">
                  <div class="ribbon ribbon-top-left">
                    <span>18% OFF</span>
                  </div>
                  <div class="trend-image">
                    <img src="images/Jaisalmer.jpg" alt="image" />
                    <div class="trend-tags">
                      <a href="#">
                        <i class="flaticon-like"></i>
                      </a>
                    </div>
                    <div class="trend-price">
                      <p class="price">
                        From <span>₹ 1,35,000</span>
                      </p>
                    </div>
                  </div>
                  <div class="trend-content">
                    <p>
                      <i class="flaticon-location-pin"></i> Jaisalmer
                    </p>
                    <h4>
                      <NavLink to="/offerpage5">Hills of Jaisalmer</NavLink>
                    </h4>
                    <div class="rating mar-bottom-10">
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star-half checked"></span>
                      <span class="fa fa-star-half checked"></span>
                    </div>
                    <span class="mar-left-5">48 Reviews</span>
                    <p class="mar-0">
                      <i class="fa fa-clock-o" aria-hidden="true"></i> 8 days &
                      7 nights
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="trend-item">
                  <div class="ribbon ribbon-top-left">
                    <span>10% OFF</span>
                  </div>
                  <div class="trend-image">
                    <img src="images/Dubai.jpg" alt="image" />
                    <div class="trend-tags">
                      <a href="#">
                        <i class="flaticon-like"></i>
                      </a>
                    </div>
                    <div class="trend-price">
                      <p class="price">
                        From <span>₹ 49,900</span>
                      </p>
                    </div>
                  </div>
                  <div class="trend-content">
                    <p>
                      <i class="flaticon-location-pin"></i> Dubai
                    </p>
                    <h4>
                      <NavLink to="/offerpage6">Dreams Of Dubai</NavLink>
                    </h4>
                    <div class="rating mar-bottom-10">
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                    </div>
                    <span class="mar-left-5">18 Reviews</span>
                    <p class="mar-0">
                      <i class="fa fa-clock-o" aria-hidden="true"></i> 5 days &
                      4 nights
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
