const AboutUs = () => {
  return (
    <div>
      <section class="breadcrumb-outer text-center">
        <div class="container">
          <div class="breadcrumb-content">
            <h2 style={{ color: "black" }}>About Us </h2>
            <nav aria-label="breadcrumb">
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/" style={{ color: "black" }}>
                    Home
                  </a>
                </li>
                <li
                  class="breadcrumb-item active"
                  aria-current="page"
                  style={{ color: "black" }}
                >
                  About Us
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="overlay"></div>
      </section>
      <section class="why-us pad-top-80">
        <div class="container">
          <div class="why-us-about mar-bottom-60">
            <div class="row display-flex">
              <div class="col-md-6 col-xs-12">
                <div class="why-about-inner">
                  <h4>Amazing Places To Enjoy </h4>
                  <h2>About FlightsWorld</h2>
                  <p>
                    Flights world is a Leading Travel Brand providing quality
                    and assured travel services to customers.Our team is
                    striving hard to become the world class travel company and
                    industry leader in the near future.We offer a complete
                    bussiness travel environment for Corporate travel,Holiday
                    packages,Domestic and International flights,Hotel
                    bookings,Car and Bus hirings,Train tickets,Travel insurance
                    across the world.
                  </p>
                  {/* <a href="#" class="biz-btn biz-btn1">
                    Read More
                  </a> */}
                </div>
              </div>
              <div class="col-md-6 col-xs-12">
                <div class="why-about-image">
                  <img src="images/hometype1.png" alt="about" />
                </div>
              </div>
            </div>
          </div>
          <div class="why-us-box">
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12">
                <div class="why-us-item display-flex">
                  <div class="why-us-icon">
                    <i class="flaticon-price"></i>
                  </div>
                  <div class="why-us-content mar-left-20">
                    <h3 class="mar-bottom-10">Competetive Pricing</h3>
                    <p class="mar-0">
                      With 500+ suppliers and the purchasing power of 300
                      million members
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12">
                <div class="why-us-item display-flex">
                  <div class="why-us-icon">
                    <i class="flaticon-call"></i>
                  </div>
                  <div class="why-us-content mar-left-20">
                    <h3 class="mar-bottom-10">Award Winning Service</h3>
                    <p class="mar-0">
                      Travel worry free knowing that here present we're here if
                      you need us, 24 hours a day
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12">
                <div class="why-us-item display-flex">
                  <div class="why-us-icon">
                    <i class="flaticon-global"></i>
                  </div>
                  <div class="why-us-content mar-left-20">
                    <h3 class="mar-bottom-10">Worldwide Coverage</h3>
                    <p class="mar-0">
                      1,200,000 hotels in more than 200 countries and regions &
                      flights to over 5,000 citites.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="about-us">
        <div class="container">
          <div class="section-title">
            <h2>
              We're truely dedicated to make your travel experiences as much
              simple and fun as possible
            </h2>
          </div>
          <div class="about-desc">
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="about-story">
                  <h3>OUR VISION</h3>
                  <p class="mar-0">
                    Creating a business model which unfollow the conventional.
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="about-mission">
                  <h3>Our Mission</h3>
                  <p class="mar-0">
                    We respect values, so do the commitments we make.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="why-us pad-top-70 bg-grey">
        <div class="container">
          <div class="section-title">
            <h2>
              Top <span>Services</span>
            </h2>
          </div>
          <div class="why-us-box">
            <div class="row">
              <div class="col-md-3 col-sm-6 col-xs-12">
                <div class="why-us-item why-us-item1 text-center">
                  <div class="why-us-icon">
                    <i class="flaticon-price"></i>
                  </div>
                  <div class="why-us-content">
                    <h3>Reliable Pricing</h3>
                    <p class="mar-0">
                      Once a year join us to the other part of the world
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-xs-12">
                <div class="why-us-item why-us-item1 text-center">
                  <div class="why-us-icon">
                    <i class="flaticon-add-user"></i>
                  </div>
                  <div class="why-us-content">
                    <h3>Easy joining</h3>
                    <p class="mar-0">
                      Contact us and in 2 minutes you're booked
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-xs-12">
                <div class="why-us-item why-us-item1 text-center">
                  <div class="why-us-icon">
                    <i class="flaticon-global"></i>
                  </div>
                  <div class="why-us-content">
                    <h3>Expert hikers</h3>
                    <p class="mar-0">
                      Our mountans teached us everything we know
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-xs-12">
                <div class="why-us-item why-us-item1 text-center">
                  <div class="why-us-icon">
                    <i class="flaticon-user"></i>
                  </div>
                  <div class="why-us-content">
                    <h3>Experienced team</h3>
                    <p class="mar-0">
                      We always lived inside our amazing nature
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default AboutUs;
