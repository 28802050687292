import React from "react";

export const Education1 = () => {
  return (
    <div>
      <section class="breadcrumb-outer text-center">
        <div class="container">
          <div class="breadcrumb-content">
            <h2 style={{ color: "black" }}>Explore</h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
        </div>
        <div class="overlay"></div>
      </section>

      <section class="flight-destinations bg-white">
        <div class="container">
          <div class="row">
            <div id="content" class="col-md-8 col-xs-12">
              <div class="detail-content content-wrapper">
                <div class="detail-info">
                  <div class="detail-info-content clearfix">
                    <h4 class="mar-0">NASA educational tour</h4>
                    <h2>USA</h2>
                    <div class="rating">
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                    </div>
                    {/* <h3 class=" detail-price mar-0 text-right">
                      $659 <br />
                      Person
                    </h3> */}
                  </div>
                </div>
                <div class="gallery detail-box about-slider">
                  <div class="gallery-item">
                    <img src="images/nasacover.jpg" alt="in_th_030_01_sm" />
                  </div>
                </div>
                <div class="description flight-table mar-bottom-30">
                  <div class="detail-title">
                    <h3>Itinerary</h3>
                  </div>
                  <div class="description mar-bottom-30">
                    <ul class="expect">
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 1:</b> DEPARTURE FROM INDIA
                      </li>
                      <li>
                        <i class="fa fa-circle"></i> <b>Day 2:</b>ARRIVAL AT
                        ORLANDO. On arrival at Orlando the group will have
                        dinner at Indian restaurant. Later they will be transfer
                        to Hotel check in, overnight stay at the Hotel.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 3:</b>ORLANDO – KENNEDY SPACE CENTER VISIT – SEA
                        WORLD VISIT After breakfast the group will leave the
                        hotel by 08:00am local time to KSC camp day 01. Students
                        will have varies activity to do in the camp like
                        Egg-Nut, design a sample rocket with help of the camp
                        instructors. Lunch will be provided at the camp. After
                        lunch the group will visit NASA center places like
                        Rocket museum, Hall of Fame, etc.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i> <b>Day 4:</b>ORLANDO –
                        KENNEDY SPACE CENTER VISIT – COCOA BEACH After breakfast
                        the group will leave the hotel by 08:00am local time to
                        KSC camp day 02. Today will explore the launch station
                        at NASA and will visit important palaces at NASA center,
                        and students will also do some more activities like
                        building a bridge, etc. Lunch will be provided at the
                        camp. In evening around 04:00pm we will depart from the
                        camp and visit Cocoa Beach. Later enjoy dinner and
                        overnight stay at the Hotel.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i> <b>Day 5:</b> ORLANDO –
                        KENNEDY SPACE CENTER VISIT – UNIVERSITY OF SOUTH FLORIDA
                        After breakfast the group will leave the hotel by
                        08:00am local time to KSC camp day 03. Graduation day
                        the students will be awarded with certificates for their
                        activities done during the camp and also explore left
                        out of NASA center tour. They will also enjoy I-Max
                        movie at the center and today Lunch would be with
                        Astronaut(with prior permission), where you can intact
                        with astronaut about space station experiences. Then we
                        will visit International Admission Officer at University
                        of South Florida where you will be briefed about the
                        admission process and will get the feel of the US
                        University and try to meet Indian Student Association.
                        We will also try and have dinner at one of the hostels.
                        Overnight stay at the Hotel.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 6:</b> ORLANDO – UNIVERSAL STUDIOS VISIT After
                        breakfast the group will leave the hotel by 10:00am
                        local time to Universal Studio & The Wizarding world of
                        Harry Potter. Where they can have fun and adventure
                        ride, they can enjoy full day and lunch will be provided
                        in the theme park itself. Later enjoy dinner and
                        overnight stay at the Hotel.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 7:</b> ORLANDO – DISNEY LAND VISIT - After
                        breakfast the group will visit to Walt Disney World.
                        Come and enjoy the magic of Walt Disney World Resort in
                        Orlando. Enjoy full day adventure rides and theme park.
                        Lunch will be provided the theme park itself. Later
                        return to the hotel. Dinner and overnight stay.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 8:</b> ORLANDO – BUFFALO – NIAGARA FALLS After
                        breakfast the group checks out the hotel and proceeds to
                        airport for flying to Buffalo (02:30 min flying time).
                        On arrival at Buffalo the group will visit the nature
                        wonder and most people dream place in USA “Niagara
                        Falls”. Enjoy the falls view from USA (maid of the mist
                        will provide if the climate permits). Later check in
                        hotel and enjoy dinner, overnight stay at the hotel.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 9:</b> BUFFALO – WASHINGTON D.C After breakfast
                        the group checks out the hotel and proceeds to
                        Washington D.C by coach, It 08:30 travel time including
                        lunch break. We will also visit Hershey chocolate
                        factory in the en-route, where they will take tour
                        inside the factory and enjoy purchasing handful of fresh
                        made chocolate for your Friends and Family. On arrival
                        at Washington D.C check in Hotel and enjoy dinner,
                        overnight stay at the hotel.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 10:</b> WASHINGTON D.C - After breakfast enjoy
                        the very best of Washington DC in one day! Aboard a
                        state-of-the-art, for a narrated 6-hour tour, see the
                        White House, the National Mall, visit Smithsonian
                        Museums and more, and enjoy guided walks around the US
                        Capitol Building, the Lincoln Memorial, the World War II
                        Memorial and the Korean War Veterans Memorial, giving
                        you a more intimate look at DC's most famous landmarks.
                        You’ll learn all about the sights you see from your
                        knowledgeable guide. Later enjoy dinner and overnight
                        stay at the Hotel.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 11:</b>WASHINGTON D.C – NEW YORK After breakfast
                        the group checks out the hotel and proceeds to New York
                        by coach, it 04:30 travel time including lunch break. On
                        arrival the group will enjoy the city tour of New York
                        along with famous Time square visit and also the Empire
                        State Building up to 86th observation deck enjoy the
                        full view of New York. Later the group will proceed to
                        dinner and check in hotel, Overnight stay at the hotel.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 12:</b>NEW YORK - After breakfast the group
                        checks out the hotel and proceeds to left out city tour
                        in New York and later the will visit another famous
                        tourist sport in USA Statue of Liberty with ferry ride.
                        Enjoy lunch and proceed to airport to fly back to India.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div id="sidebar" class="col-md-4 col-xs-12">
              <aside class="detail-sidebar sidebar-wrapper">
                <div class="sidebar-item">
                  <form class="filter-box">
                    <h3 class="white">For Enquiry</h3>
                    <div class="row">
                      <div class="col-xs-12">
                        <div class="form-group">
                          <label class="white">Your Name</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Name"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="white">Your Email</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Email"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="white">Your Phone</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Phone"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-xs-12">
                        <div class="form-group mar-top-15">
                          <a href="#" class="biz-btn">
                            Submit
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="sidebar-item">
                  <div class="detail-title">
                    <h3>Most Visited</h3>
                  </div>
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/na1.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/na2.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/na3.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/na4.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/na5.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
