import React from "react";
import emailjs from "emailjs-com";
import swal from "sweetalert";

export const OfferPage2 = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "template_58obn2m",
        e.target,
        "user_aKCieJwSXAcLTXl3NIl4R"
      )
      .then(
        (result) => {
          swal({
            title: "Done!",
            text: "We will get back to you shortly!",
            icon: "success",
            timer: 3000,
            button: false,
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }
  return (
    <div>
      <section class="list">
        <div class="container">
          <div class="row">
            <div class="col-md-8 pad-left-30 pull-right">
              <div class="blog-list">
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/andaman1.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>01</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">
                            Arrival – Port Blair – Cellular Jail – Light & Sound
                            Show
                          </a>
                        </h3>

                        <p>
                          Arrival to Port Blair. Our representative will meet
                          and assist you at the Veer Savarkar Airport. Board the
                          waiting vehicles, drive to your hotel and check in.
                          First half of the day is at leisure. Later in the
                          afternoon visit Cellular jail followed by light and
                          sound show. The Cellular Jail has a pride of place in
                          India’s freedom struggle. Many a stalwart were
                          imprisoned here. Declared a national memorial, this
                          three storey prison constructed by Britishers in 1906,
                          is a pilgrimage destination for freedom fighters. This
                          colossal edifice has mutely witnessed the most
                          treacherous of inhumane atrocities borne by the
                          convicts, who were mostly freedom fighters. The day
                          draws to a gentle close with the Sound and Light Show
                          at the historic Cellular Jail which gives you a vivid
                          experience of its strong association with the freedom
                          fighters and their struggle for independence.
                          Overnight stay in the Hotel.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">Port Blair- Havelock</a>
                        </h3>

                        <p>
                          Today after breakfast you will transfer to Jetty. Head
                          to Havelock Island on board Cruise connecting the two
                          islands! Havelock, about 54 kilometres northeast of
                          Port Blair, the Island is a hidden jewel. Few Indians
                          know of this stunning beach destination in their own
                          backyard while heading for Phuket (in Thailand) across
                          the waters of Andaman Sea. Havelock is a serene
                          tropical island encompassing about 86 square
                          kilometres. Its clear waters are rated among the best
                          in the world for snorkelling and scuba diving. Close
                          to 1000 different species of marines life inhabit the
                          waters of the Andaman Islands. Scuba divers can see
                          nudibranchs, sea slugs and snails, varieties of ghost
                          pipe fish, jaw fish and a number of different
                          crustaceans of all shapes and sizes. With its serene
                          beaches, 40 meters visibility underwater, colourful
                          coral reefs and ‘far from the maddening crowd’ appeal,
                          Havelock Island has emerged as a dream destination
                          among discerning travellers. Arrive at Havelock Jetty,
                          you are met and assisted by our representative. Board
                          the waiting vehicles and drive to the hotel. Check in
                          at the hotel. Overnight stay at the Hotel.
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/andaman2.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>02</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/andaman3.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>03</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">
                            Havelock – Radha Nagar Beach (or Beach No. 7)
                          </a>
                        </h3>

                        <p>
                          Today after breakfast, proceed for Radha Nagar Beach
                          (or Beach No. 7) is the jewel in the crown of Havelock
                          Island. Located on the western side of the island, it
                          stands away from the cluster. The glorious white sand
                          beach shot to global fame when Time magazine (in 2004)
                          rated it as the finest in Asia. With lush tropical
                          forest rising on a hill to one side, the draw of this
                          white sand beach has steadily grown in the legion of
                          avid beach buffs. The popularity of this beach has
                          triggered cruises from across the Andaman Sea.
                          Travellers cruise over a few days from Thailand to
                          experience the magic of this beach. A wide beach that
                          runs over a few kilometres, Radhanagar beach is simply
                          stunning. It lends itself to a variety of activities.
                          Overnight stay at the Hotel.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">
                            Havelock – Port Blair Ross Island
                          </a>
                        </h3>

                        <p>
                          After breakfast transfer to Havelock Jetty. From here
                          sail to Port Blair on board Cruise. Upon Arrival check
                          into the hotel. Overnight stay at the Hotel. After
                          reached to port Blair embark on a day long three
                          island cruise. One of the most sought after
                          activities. It covers a panorama of seven harbours
                          that include the Ross Island. It leaves a lasting
                          impression. Visit Ross Island. Once the administrative
                          headquarters of the British and capital of these
                          Islands, the island is now under the Indian Navy. It
                          stands now as a ruin of the bygone days with the old
                          structures almost in debris. There is a small museum
                          named Smritika, it holds photographs and the other
                          antiques of the Britishers relevant to these islands.
                          The island is famous for friendly flocks of Deer and
                          lush green walking trails.
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/andaman4.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>04</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/andaman5.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>05</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="blog-single.html">Depart from Port Blair</a>
                        </h3>

                        <p>
                          Drop to Airport / Harbour for Return journey with a
                          wonderful holiday memories.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-xs-12">
              <div class="list-sidebar">
                <div class="sidebar-item">
                  <form class="filter-box" onSubmit={sendEmail}>
                    <h3 class="white">Enquire This Package</h3>
                    <div class="row">
                      <div class="col-xs-12">
                        <div class="form-group">
                          <label class="white">Your Name</label>
                          <div class="input-box">
                            <i class="flaticon-user"></i>
                            <input
                              type="text"
                              placeholder="Name"
                              style={{ paddingRight: "6px" }}
                              name="name"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label class="white">Check In</label>
                          <div class="input-box">
                            <i class="flaticon-calendar"></i>
                            <input
                              id="date-range0"
                              type="text"
                              placeholder="yyyy-mmm-dd"
                              name="datein"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label class="white">Check Out</label>
                          <div class="input-box">
                            <i class="flaticon-calendar"></i>
                            <input
                              id="date-range1"
                              type="text"
                              placeholder="yyyy-mm-dd"
                              name="dateout"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label class="white">Adult</label>
                          <div class="input-box">
                            <i class="flaticon-add-user"></i>
                            <select class="niceSelect">
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label class="white">Children</label>
                          <div class="input-box">
                            <i class="flaticon-add-user"></i>
                            <select class="niceSelect">
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="comment-btn text-right mar-top-15">
                        <input
                          type="submit"
                          class="biz-btn"
                          id="submit"
                          value="Enquire Now"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div class="sidebar-item">
                  <h3>Services</h3>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>24/7 Reception</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Parking</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Bar</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Restaurant</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Satellite Television</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Lift/ELevator</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Luggage Storage </label>
                    </div>
                  </div>
                </div>

                <div class="sidebar-item">
                  <h3>Star Rating</h3>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        <div class="star-rating">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        <div class="star-rating">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        <div class="star-rating">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        <div class="star-rating">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        <div class="star-rating">
                          <span class="fa fa-star checked"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="sidebar-item">
                  <h3>City</h3>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        Amsterdam<span class="number">749</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" checked />
                    <div class="state">
                      <label>
                        Rotterdam<span class="number">630</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        Copenghan<span class="number">58</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        New Delhi<span class="number">29</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        New York<span class="number">29</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        Kathmandu<span class="number">29</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        Brisbane<span class="number">29</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        Tokyo<span class="number">29</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
