import React from "react";
import { Bounce, Zoom, Rotate } from "react-reveal";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
const Banner = () => {
  return (
    <div>
      <section class="banner">
        <div class="slider">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="slide-inner">
                  <div
                    class="slide-image"
                    style={{
                      backgroundImage: "url(images/slider/slider-1.webp)",
                    }}
                  ></div>

                  <div class="swiper-content">
                    <Bounce top>
                      <h1
                        style={{
                          color: "white",
                          textShadow: "2px 2px #0c0c0c",
                        }}
                      >
                        Time To Travel
                      </h1>
                    </Bounce>
                    <p class="mar-bottom-20">
                      {/* <Zoom bottom cascade>
                        Foresee the pain and trouble that are bound to ensue and
                        equal fail in their duty through weakness.
                      </Zoom> */}
                    </p>
                    <li class="biz-btn">
                      <NavLink to="/">
                        <ScrollLink
                          exact
                          to="flights"
                          spy={true}
                          smooth={true}
                          duration={200}
                          offset={-250}
                          className="nav-link btn__appointment"
                          style={{ color: "white" }}
                        >
                          Explore More
                        </ScrollLink>
                      </NavLink>
                    </li>
                    {/* <a href="#" class="biz-btn">
                      Explore More
                    </a> */}
                    <NavLink to="/contactus" class="biz-btn mar-left-10">
                      Contact Us
                    </NavLink>
                  </div>

                  <div class="overlay"></div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="slide-inner">
                  <div
                    class="slide-image"
                    style={{
                      backgroundImage: "url(images/slider/slider-2.webp)",
                    }}
                  ></div>
                  <div class="swiper-content">
                    <Rotate left>
                      <h1>Explore The World</h1>
                    </Rotate>
                    <p class="mar-bottom-20">
                      <Zoom right cascade>
                        Discover your next great adventure,
                      </Zoom>
                    </p>
                    <li class="biz-btn">
                      <NavLink to="/">
                        <ScrollLink
                          exact
                          to="findmore"
                          spy={true}
                          smooth={true}
                          duration={200}
                          offset={-250}
                          className="nav-link btn__appointment"
                          style={{ color: "white" }}
                        >
                          Find More
                        </ScrollLink>
                      </NavLink>
                    </li>
                  </div>
                  <div class="overlay"></div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="slide-inner">
                  <div
                    class="slide-image"
                    style={{
                      backgroundImage: "url(images/slider/slider-3.jpg)",
                    }}
                  ></div>
                  <div class="swiper-content">
                    <Zoom bottom>
                      <h1>Travel, Adventures, Holidays</h1>
                    </Zoom>
                    <p class="mar-bottom-20">
                      <Bounce right>
                        Discover Beautiful Places in the World With Flights
                        World
                      </Bounce>
                    </p>
                    <li class="biz-btn">
                      <NavLink to="/">
                        <ScrollLink
                          exact
                          to="dealsandoffers"
                          spy={true}
                          smooth={true}
                          duration={200}
                          offset={-250}
                          className="nav-link btn__appointment"
                          style={{ color: "white" }}
                        >
                          View More
                        </ScrollLink>
                      </NavLink>
                    </li>
                  </div>
                  <div class="overlay"></div>
                </div>
              </div>
            </div>

            {/* <div class='swiper-button-next'></div>
            <div class='swiper-button-prev'></div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Banner;
