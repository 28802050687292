import React from "react";
import emailjs from "emailjs-com";
import swal from "sweetalert";

export const OfferPage1 = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "template_58obn2m",
        e.target,
        "user_aKCieJwSXAcLTXl3NIl4R"
      )
      .then(
        (result) => {
          swal({
            title: "Done!",
            text: "We will get back to you shortly!",
            icon: "success",
            timer: 3000,
            button: false,
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <div>
      <section class="list">
        <div class="container">
          <div class="row">
            <div class="col-md-8 pad-left-30 pull-right">
              <div class="blog-list">
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/1.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>01</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">
                            New Jalpaiguri Junction railway / IXB Airport –
                            Gangtok
                          </a>
                        </h3>

                        <p>
                          Meet & Greet on arrival at NJP Railway Station / IXB
                          Airport & transfer to Gangtok. On arrival Check-in to
                          hotel & rest of the day at leisure. Overnight stay
                          will be at Gangtok.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">
                            Excursion to Tsomgo Lake & Baba Mandir
                          </a>
                        </h3>

                        <p>
                          After breakfast start for an excursion to Tsomgo Lake
                          (12,400 ft.) & Baba Mandir (13,200 ft.) which is 55
                          kms one way from Gangtok city. Overnight stay at
                          Gangtok. (In case of Land slide or any other reason
                          Tsomgo Lake is closed we will provide alternate
                          sightseeing.)
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/2.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>02</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/3.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>03</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">
                            Gangtok Local Sightseeing
                          </a>
                        </h3>

                        <p>
                          Start for a Full day city tour covering Tashi view
                          point, Ganesh Tok, Hanuman Tok, Flower show, Cottage
                          Industry and Handicraft Centre (Closed on Sunday and
                          1st & 3rd Saturday of the month), Institute of
                          Tibetology (Closed on Sunday), Dro-dul Chorten,
                          Gonjang Monastery & Banjakhri Falls (maximum 6 hours).
                          Overnight stay at Gangtok.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">
                            Gangtok – Pelling (150 kms / 5 hrs) via Chardham
                          </a>
                        </h3>

                        <p>
                          After breakfast start for Pelling (6,300 ft.). Enroute
                          visit Siddheshwar Dham (Chardham) and Sai Temple in
                          Namchi. On arrival check-in to your hotel. Overnight
                          stay at Pelling
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/4.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>04</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/5.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>05</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="blog-single.html">Pelling Sightseeing</a>
                        </h3>

                        <p>
                          After breakfast start for full day sightseeing. 1st
                          Half tour - Darap village, Rimbi water Falls,
                          Khecheopalri Lake & Khangchendzonga waterfalls. In
                          afternoon start for 2nd half tour - Pemayangtse
                          Monastery, Rabdentse Ruins, Sang-Ngag Choling (Sky
                          Walk), New Helipad Ground. Overnight stay at Pelling.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">Pelling – Darjeeling</a>
                        </h3>

                        <p>
                          This morning after breakfast transfer to Darjeeling
                          (6,950 ft.) via Singla check post. On arrival check-in
                          to your hotel & rest of the day free at leisure.
                          Overnight stay at Darjeeling.
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/6.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>06</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/7.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>07</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">Darjeeling Sightseeing</a>
                        </h3>

                        <p>
                          Early morning (4:00 AM) visit Tiger Hill (Subject to
                          availability of Permit / Token) back to hotel. After
                          breakfast start at 8.30 AM visit Himalayan
                          Mountaineering Institute, P.N. Zoological Park
                          (Thursday closed), Tenzing Rock, Ropeway, Tibetan
                          Refugee self-help Centre (Sunday closed), Tea Garden
                          (outer view), Japanese Temple, Ghoom Monastery,
                          Batasia loop. Evening free for shopping or leisure.
                          Overnight stay at Darjeeling
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">
                            Darjeeling –New Jalpaiguri Railway / IXB Airport
                          </a>
                        </h3>

                        <p>
                          After breakfast Check-out from hotel and take the
                          departure transfers to NJP Railway Station / IXB
                          Airport for your onward journey.
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/8.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>08</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-xs-12">
              <div class="list-sidebar">
                <div class="sidebar-item">
                  <form class="filter-box" onSubmit={sendEmail}>
                    <h3 class="white">Enquire This Package</h3>
                    <div class="row">
                      <div class="col-xs-12">
                        <div class="form-group">
                          <label class="white">Your Name</label>
                          <div class="input-box">
                            <i class="flaticon-user"></i>
                            <input
                              type="text"
                              placeholder="Name"
                              style={{ paddingRight: "6px" }}
                              name="name"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label class="white">Check In</label>
                          <div class="input-box">
                            <i class="flaticon-calendar"></i>
                            <input
                              id="date-range0"
                              type="text"
                              placeholder="yyyy-mmm-dd"
                              name="datein"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label class="white">Check Out</label>
                          <div class="input-box">
                            <i class="flaticon-calendar"></i>
                            <input
                              id="date-range1"
                              type="text"
                              placeholder="yyyy-mm-dd"
                              name="dateout"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label class="white">Adult</label>
                          <div class="input-box">
                            <i class="flaticon-add-user"></i>
                            <select class="niceSelect">
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label class="white">Children</label>
                          <div class="input-box">
                            <i class="flaticon-add-user"></i>
                            <select class="niceSelect">
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="comment-btn text-right mar-top-15">
                        <input
                          type="submit"
                          class="biz-btn"
                          id="submit"
                          value="Enquire Now"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div class="sidebar-item">
                  <h3>Services</h3>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>24/7 Reception</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Parking</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Bar</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Restaurant</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Satellite Television</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Lift/ELevator</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Luggage Storage </label>
                    </div>
                  </div>
                </div>

                <div class="sidebar-item">
                  <h3>Star Rating</h3>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        <div class="star-rating">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        <div class="star-rating">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        <div class="star-rating">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        <div class="star-rating">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        <div class="star-rating">
                          <span class="fa fa-star checked"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="sidebar-item">
                  <h3>City</h3>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        Amsterdam<span class="number">749</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" checked />
                    <div class="state">
                      <label>
                        Rotterdam<span class="number">630</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        Copenghan<span class="number">58</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        New Delhi<span class="number">29</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        New York<span class="number">29</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        Kathmandu<span class="number">29</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        Brisbane<span class="number">29</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        Tokyo<span class="number">29</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
