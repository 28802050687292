import React from "react";
import { Link, NavLink } from "react-router-dom";

export const Footer = () => {
  return (
    <div>
      <footer>
        <div class="footer-upper pad-bottom-50">
          <div class="container">
            <div class="row">
              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="footer-about">
                  <div class="footer-about-in mar-bottom-30">
                    <h3 class="white">Need flightsworld Help?</h3>
                    <div class="footer-phone">
                      <div class="cont-icon">
                        <i class="flaticon-call"></i>
                      </div>
                      <div class="cont-content mar-left-20">
                        <p class="mar-0">Got Questions? Call us 24/7!</p>
                        <p class="bold mar-0">
                          <span>Call Us:</span> +91 8921843284
                        </p>
                      </div>
                    </div>
                  </div>
                  <h3 class="white">Contact Info</h3>
                  <p>AMABALAMUKKU || Kowdiar || TRIVANDRUM</p>
                  <ul class="social-links">
                    <li>
                      <a
                        href="https://www.facebook.com/www.flightsworld.in/"
                        target="_blank"
                      >
                        <i class="fab fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fab fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/flights_world/"
                        target="_blank"
                      >
                        <i class="fab fa-instagram" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fab fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-xs-12">
                <div class="footer-links">
                  <h3 class="white">Company</h3>
                  <ul>
                    <li>
                      <Link to="/aboutus">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contactus">Contact</Link>
                    </li>
                    <li>
                      <NavLink to="/">Terms of Use</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-2 col-sm-6 col-xs-12">
                <div class="footer-links">
                  <h3 class="white">Support</h3>
                  <ul>
                    <li>
                      <NavLink to="/contactus">Account</NavLink>
                    </li>

                    <li>
                      <NavLink to="/">FAQ</NavLink>
                    </li>
                    <li>
                      <NavLink to="/privacy">Privacy Policy</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-3 col-sm-12 col-xs-12">
                <div class="footer-subscribe">
                  <h3 class="white">Mailing List</h3>
                  <p class="white">
                    Sign up for our mailing list to get latest Enquiries and
                    offers
                  </p>
                  <form>
                    <input type="email" placeholder="Your Email" />
                    <a href="#" class="biz-btn mar-top-15">
                      Updates
                    </a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="footer-payment pad-top-30 pad-bottom-30 bg-white">
          <div class="container">
            <div class="pay-main display-flex space-between">
              <div class="footer-logo pull-left">
                <a href="/">
                  <img
                    src="images/logo-black.png"
                    alt="image"
                    style={{ width: "277px" }}
                  />
                </a>
              </div>
              <div class="footer-payment-nav pull-right">
                <ul>
                  <li>
                    <img src="images/payment/mastercard.png" alt="image" />
                  </li>
                  <li>
                    <img src="images/payment/paypal.png" alt="image" />
                  </li>
                  <li>
                    <img src="images/payment/skrill.png" alt="image" />
                  </li>
                  <li>
                    <img src="images/payment/visa.png" alt="image" />
                  </li>
                  <li>
                    <select>
                      <option>English (United States)</option>
                      <option>English (United States)</option>
                      <option>English (United States)</option>
                      <option>English (United States)</option>
                      <option>English (United States)</option>
                    </select>
                  </li>
                  <li>
                    <select>
                      <option>$ USD</option>
                      <option>$ USD</option>
                      <option>$ USD</option>
                      <option>$ USD</option>
                      <option>$ USD</option>
                    </select>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}
        <div class="footer-copyright">
          <div class="container">
            <div class="copyright-text pull-left">
              <p class="mar-0">
                © 2020. flights-world.com. All Rights Reserved.{" "}
              </p>
            </div>
            <div class="footer-nav pull-right" style={{ color: "white" }}>
              Powered By <a href="http://www.forcodetech.com/">Forcodetech </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
