import React from "react";

export const Faq = () => {
  return (
    <div>
      <section class="breadcrumb-outer text-center">
        <div class="container">
          <div class="breadcrumb-content">
            <h2 style={{ color: "black" }}>FAQ </h2>
            <nav aria-label="breadcrumb">
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/" style={{ color: "black" }}>
                    Home
                  </a>
                </li>
                <li
                  class="breadcrumb-item active"
                  aria-current="page"
                  style={{ color: "black" }}
                >
                  FAQ
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="overlay"></div>
      </section>
      <section class="faq">
        <div class="container">
          <div class="row">
            <div class="col-md-8 col-xs-12 pull-right">
              <div class="faq-content">
                <h3 class="mar-bottom-30">Payment</h3>
                <div class="faq-item mar-bottom-20">
                  <button
                    type="button"
                    class="btn btn-info"
                    data-toggle="collapse"
                    data-target="#faq1"
                  >
                    <i class="fa fa-minus" aria-hidden="true"></i>
                  </button>
                  <p class="question">
                    I'm a solo Nepayatri, is there a single supplement?
                  </p>
                  <div id="faq1" class="collapse in faq-para">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </div>
                </div>
                <div class="faq-item mar-bottom-20">
                  <button
                    type="button"
                    class="btn btn-info"
                    data-toggle="collapse"
                    data-target="#faq2"
                  >
                    <i class="fa fa-minus" aria-hidden="true"></i>
                  </button>
                  <p class="question">
                    Which currency is most widely accepted on this tour?
                  </p>
                  <div id="faq2" class="collapse faq-para">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </div>
                </div>
                <div class="faq-item mar-bottom-20">
                  <button
                    type="button"
                    class="btn btn-info"
                    data-toggle="collapse"
                    data-target="#faq3"
                  >
                    <i class="fa fa-minus" aria-hidden="true"></i>
                  </button>
                  <p class="question">
                    Should I book pre/post tour accomodation?
                  </p>
                  <div id="faq3" class="collapse faq-para">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </div>
                </div>
                <div class="faq-item mar-bottom-20">
                  <button
                    type="button"
                    class="btn btn-info"
                    data-toggle="collapse"
                    data-target="#faq4"
                  >
                    <i class="fa fa-minus" aria-hidden="true"></i>
                  </button>
                  <p class="question">How do I edit the calendar?</p>
                  <div id="faq4" class="collapse faq-para">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </div>
                </div>
                <div class="faq-item mar-bottom-20">
                  <button
                    type="button"
                    class="btn btn-info"
                    data-toggle="collapse"
                    data-target="#faq5"
                  >
                    <i class="fa fa-minus" aria-hidden="true"></i>
                  </button>
                  <p class="question">Why was my listing deactivated?</p>
                  <div id="faq5" class="collapse faq-para">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </div>
                </div>
                <div class="faq-item">
                  <button
                    type="button"
                    class="btn btn-info"
                    data-toggle="collapse"
                    data-target="#faq6"
                  >
                    <i class="fa fa-minus" aria-hidden="true"></i>
                  </button>
                  <p class="question">
                    How do I turn off or delete my listing?
                  </p>
                  <div id="faq6" class="collapse faq-para">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-xs-12">
              <div class="faq-sidebar">
                <div class="sidebar-item">
                  <h3 class="mar-bottom-30">All Categories</h3>
                  <ul class="sidebar-category">
                    <li>
                      <a href="#">All</a>
                    </li>
                    <li>
                      <a href="#">Featured</a>
                    </li>
                    <li>
                      <a href="#">Sliders</a>
                    </li>
                    <li class="active">
                      <a href="#">Manage Listings</a>
                    </li>
                    <li>
                      <a href="#">Address and Map</a>
                    </li>
                    <li>
                      <a href="#">Reservation Requests</a>
                    </li>
                    <li>
                      <a href="#">Your Reservation</a>
                    </li>
                    <li>
                      <a href="#">Search Results</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
