import React from "react";

export const Education5 = () => {
  return (
    <div>
      <section class="breadcrumb-outer text-center">
        <div class="container">
          <div class="breadcrumb-content">
            <h2 style={{ color: "black" }}>Explore</h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
        </div>
        <div class="overlay"></div>
      </section>

      <section class="flight-destinations bg-white">
        <div class="container">
          <div class="row">
            <div id="content" class="col-md-8 col-xs-12">
              <div class="detail-content content-wrapper">
                <div class="detail-info">
                  <div class="detail-info-content clearfix">
                    <h4 class="mar-0">Splendors of Europe</h4>
                    <h2>Europe</h2>
                    <div class="rating">
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                    </div>
                    {/* <h3 class=" detail-price mar-0 text-right">
                      $659 <br />
                      Person
                    </h3> */}
                  </div>
                </div>
                <div class="gallery detail-box about-slider">
                  <div class="gallery-item">
                    <img src="images/europe.webp" alt="in_th_030_01_sm" />
                  </div>
                </div>
                <div class="description flight-table mar-bottom-30">
                  <div class="detail-title">
                    <h3>Itinerary</h3>
                  </div>
                  <div class="description mar-bottom-30">
                    <ul class="expect">
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 1:</b> After your arrival in London, move to the
                        hotel by coach. Enjoy the rest of the day at your
                        leisure or travel around London on your own. Spend your
                        overnight stay at the hotel.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i> <b>Day 2:</b>After morning
                        breakfast, take a tour to London. Visit London tower
                        which is a significant castle on the north bank of the
                        River Thames in central London and then visit London eye
                        The entire structure is 135 metres (443 feet) tall and
                        the wheel has a diameter of 120 meters 394 feet . Take a
                        break for lunch at Indian restaurant. After lunch, the
                        day is free for relaxation and night stay at hotel.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 3:</b> After a delicious breakfast, move around
                        the London city. Later, visit Madame Tussauds Wax
                        Museum. You can enjoy the evening time in the hotel.
                        Spend your overnight stay at the hotel.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i> <b>Day 4:</b>Have a healthy
                        breakfast and get ready to move to London St. Pancras
                        station to board Eurotunnel. On reaching Paris, relocate
                        to Indian restaurant. After a delicious lunch, visit
                        Eiffel Tower level 3. In the evening, relax at
                        sightseeing in Paris. Return back to the hotel for
                        overnight stay.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i> <b>Day 5:</b>After morning
                        breakfast, relax at hotel and in the evening you will
                        then be taken to an esteemed inhabited area near the
                        Bois de Boulogne prior to enjoy one-hour glide on the
                        River Seine. Move back to the hotel for overnight stay.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 6:</b> After morning breakfast, locate to
                        Disneyland and spend full day time at theme park and
                        evening return to hotel. Get back for overnight stay to
                        the hotel.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 7:</b> On the last day morning, enjoy the
                        shopping. Later, transfer to International airport to
                        board a flight to India.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div id="sidebar" class="col-md-4 col-xs-12">
              <aside class="detail-sidebar sidebar-wrapper">
                <div class="sidebar-item">
                  <form class="filter-box">
                    <h3 class="white">For Enquiry</h3>
                    <div class="row">
                      <div class="col-xs-12">
                        <div class="form-group">
                          <label class="white">Your Name</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Name"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="white">Your Email</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Email"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="white">Your Phone</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Phone"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-xs-12">
                        <div class="form-group mar-top-15">
                          <a href="#" class="biz-btn">
                            Submit
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="sidebar-item">
                  <div class="detail-title">
                    <h3>Most Visited</h3>
                  </div>
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/europe11.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/europe12.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
