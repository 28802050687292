import React from "react";

export const Education3 = () => {
  return (
    <div>
      <section class="breadcrumb-outer text-center">
        <div class="container">
          <div class="breadcrumb-content">
            <h2 style={{ color: "black" }}>Explore</h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
        </div>
        <div class="overlay"></div>
      </section>

      <section class="flight-destinations bg-white">
        <div class="container">
          <div class="row">
            <div id="content" class="col-md-8 col-xs-12">
              <div class="detail-content content-wrapper">
                <div class="detail-info">
                  <div class="detail-info-content clearfix">
                    <h4 class="mar-0">Exciting Canadian Rockies</h4>
                    <h2>Canada</h2>
                    <div class="rating">
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                    </div>
                    {/* <h3 class=" detail-price mar-0 text-right">
                      $659 <br />
                      Person
                    </h3> */}
                  </div>
                </div>
                <div class="gallery detail-box about-slider">
                  <div class="gallery-item">
                    <img src="images/canada001.webp" alt="in_th_030_01_sm" />
                  </div>
                </div>
                <div class="description flight-table mar-bottom-30">
                  <div class="detail-title">
                    <h3>Itinerary</h3>
                  </div>
                  <div class="description mar-bottom-30">
                    <ul class="expect">
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 1:</b> Welcome to Toronto, Ontario's capital and
                        Canada's biggest city. Spend your day by relaxing or
                        exploring at the city. Enjoy your overnight stay at the
                        hotel.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i> <b>Day 2:</b>Skirt the
                        seashore of Lake Ontario to Niagara Falls. It is one of
                        the Natural Wonders of the World. Get on your
                        sightseeing boat for an exciting ride to view the
                        thundering falls. Touring incorporates the lovely Floral
                        Clock. See Toronto's real milestones on today's city
                        visit, including the managing an account area, the CN
                        Tower, City Hall, the University of Toronto, and
                        ultra-current Roger's Center. You can appreciate a
                        discretionary trip to the highest point of the CN Tower
                        for a great supper and tremendous perspectives.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 3:</b> Leave Ontario's capital and stop in
                        Kingston, a city with an energizing history going back
                        more than 300 years. Move ahead along the St. Lawrence
                        River with awe inspiring vistas of St. Lawrence Islands
                        National Park. On Ivy Lea, board your watercraft for a
                        CRUISE that takes a grand course through the numerous
                        islands of St. Lawrence Islands National Park. Next,
                        move to Ottawa which is Canada's excellent capital.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i> <b>Day 4:</b>On this
                        morning, enjoy a guided sightseeing tour of this lovely
                        city on the banks of the Ottawa River and the Rideau
                        Canal. See the Canadian Mint; the embassy district;
                        stately Parliament Hill; and Rideau Falls, named for the
                        curtain-like appearance they give. Visit the Royal
                        Canadian Mounted Police Stables, an honour to Canadian
                        inheritance and record. Proposal for this afternoon
                        include a visit to the magnificent Canadian Museum of
                        Civilization or the array of restaurants and shops at
                        lively Byward Market.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i> <b>Day 5:</b>Drive into the
                        French-Canadian province of Québec, where there is an
                        unexpected change in language and tradition. Tag along
                        The King’s Highway, tracing this historical route along
                        the north shore of the mighty St. Lawrence River. Visit
                        the Basilica of Notre Dame and CAP DE LA MADELEINE
                        before arriving into historic Québec City, the heart of
                        French Canada and a UNESCO World Heritage Site.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 6:</b> Early today, join your kindred voyaging
                        colleagues on a novel discretionary trip to the
                        Erabliere Chemin du Roy Sugar Shack. Guests to the sugar
                        lodge will delight in a normal Québec breakfast,
                        alongside a guided visit and presentation to clarify the
                        procedure of maple extraction. A short time later, come
                        back to Québec City for a guided voyage through Canada's
                        just walled city, delegated by the turrets of Château
                        Frontenac. Until the British triumph of 1759, Old Québec
                        was the focal point of New France; today, it’s an UNESCO
                        World Heritage Site. Inside the dividers live the Place
                        d'armes, Laval Seminary, the star-plan Citadelle with
                        amazing vistas from its bastions, and aged Place Royale.
                        Additionally see the stone houses gathering the
                        restricted roads of Lower Town by the St. Lawrence
                        River. The evening and nighttime are free—your Tour
                        Director will have proposals for touring, shopping, and
                        eating.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 7:</b> Move on to Montreal which is one of
                        Canada's biggest urban areas. Significant attractions on
                        this present evening's visit incorporate once-walledold
                        Montréal, NOTRE DAME BASILICA, the Seminary of St.
                        Sulpice, Mount Royal, and current Montreal.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 8:</b>Today morning after breakfast, move to
                        airport to catch a flight to India with sweet memories
                        of Canada.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div id="sidebar" class="col-md-4 col-xs-12">
              <aside class="detail-sidebar sidebar-wrapper">
                <div class="sidebar-item">
                  <form class="filter-box">
                    <h3 class="white">For Enquiry</h3>
                    <div class="row">
                      <div class="col-xs-12">
                        <div class="form-group">
                          <label class="white">Your Name</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Name"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="white">Your Email</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Email"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="white">Your Phone</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Phone"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-xs-12">
                        <div class="form-group mar-top-15">
                          <a href="#" class="biz-btn">
                            Submit
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="sidebar-item">
                  <div class="detail-title">
                    <h3>Most Visited</h3>
                  </div>
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/canada11.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/canada12.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/canada13.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
