import React from "react";
import emailjs from "emailjs-com";
import swal from "sweetalert";
export const OfferPage4 = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "template_58obn2m",
        e.target,
        "user_aKCieJwSXAcLTXl3NIl4R"
      )
      .then(
        (result) => {
          swal({
            title: "Done!",
            text: "We will get back to you shortly!",
            icon: "success",
            timer: 3000,
            button: false,
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }
  return (
    <div>
      <section class="list">
        <div class="container">
          <div class="row">
            <div class="col-md-8 pad-left-30 pull-right">
              <div class="blog-list">
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/india1.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>01</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">MANGALORE</a>
                        </h3>

                        <p>
                          On arrival at Mangalore airport after you have cleared
                          your immigration and customs formalities, our
                          representative will meet you and will transfer you to
                          the hotel for check in. Mangalore was originally a
                          major trading centre owing to its favorable location
                          situated in the southern tip of Karnataka bordered by
                          Arabian Sea and Western Ghats. Although the beaches
                          and temples of Mangalore are its prime attractions,
                          the town by itself is worth a wander, with its winding
                          streets lined with quaint houses holds a unique
                          culture exhibits a blend of Kerala, Karnataka and
                          various other settlers. Overnight stay at Mangalore.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">BEKAL</a>
                        </h3>

                        <p>
                          Check out from the hotel and drive to Bekal, a
                          fantastic place of natural beauty, wonderful coastline
                          involving extended stretches of palm trees, exquisite
                          estuaries and breathtaking backwaters. Arrive at Bekal
                          and check in to the hotel. Major attraction here is
                          the Bekal fort, the largest fort in Kerala spreaded
                          over 40 acres appears to have been built up from the
                          sea since almost three fourths of its exterior is
                          drenched and the waves continuously stroke the
                          citadel. Overnight stay at Bekal.
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/india2.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>02</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/india3.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>03</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">COORG</a>
                        </h3>

                        <p>
                          Check out from the hotel after breakfast and proceed
                          to Madikeri,the major town in Coorg district (also
                          known as Kodagu),known for its green fields, coffee
                          plantations, Orange orchards and couple of waterfalls.
                          Located in the eastern slopes of Western Ghats Kodagu
                          is an administrative district in Karnataka. On the way
                          visit Kushalnagar Golden Temple, Tibetan Colony and
                          Nisargadhama. On arrival check in to the hotel. Rest
                          of the day you are at leisure for your own activities.
                          Overnight stay at Coorg.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">COORG</a>
                        </h3>

                        <p>
                          Start your day with a visit to Coorg. Magnificent
                          attraction in Kodagu is the Madikeri Fort built of mud
                          and later on replaced by a concrete stone by Tipu
                          Sultan. It is an imposing fort and hardly there is any
                          other fort that matches its grandeur. Raja's seat, an
                          ideal place to explore the exquisiteness and enjoy the
                          panoramic beauty of the mist covered blue hills and
                          valleys and to relish the sunset. It has been
                          developed into a public garden attracts large number
                          of tourists. Omkareshwara temple, dedicated to Shiva,
                          was built in the 19 th century in a mix of Gothic and
                          Islamic styles. It was built by Linga Rajendra in
                          1820. There is a pool in front of the temple with tons
                          of fresh water fishes in it. The temple has four
                          minarets around it and a dome in the center (similar
                          to a Muslim Masjid). Overnight stay at Coorg.
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/india4.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>04</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/india5.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>05</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="blog-single.html">KABINI</a>
                        </h3>

                        <p>
                          Check out from the hotel after breakfast and drive to
                          Kabini, one of the most popular wildlife destinations
                          in India situated on the banks of the river Kabini
                          comprises the south eastern part of Nagarhole National
                          Park (Rajiv Gandhi National Park), once the hunting
                          ground of Mysore Kings located 94 km away from Mysore
                          has lush greenery, attractive streams, valleys and
                          waterfalls is an abode for many rare species of
                          animals including tiger, Nilgiri Thar, Nilgiri
                          languor, bison, leopard, wild boar, deer, dhole (wild
                          dog), sloth bear, jungle cat, civet, bonnet macaque,
                          and pangolin and one among the few natural habitats of
                          Asian elephants. The bird species include Malabar pied
                          hornbill, crested hawk eagle, golden- back woodpecker,
                          and southern tree pie. Reptiles like crocodiles,
                          pythons, and several species of snakes and lizards are
                          also found here. Arrive at Kabini and check in to the
                          resort. Overnight stay at Kabini.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">KABINI</a>
                        </h3>

                        <p>
                          Start your day with a guided tour of Kabini. Enjoy a
                          cycling tour and a coracle ride. Later go for bird
                          watching. If you are so much inclined, proceed for a
                          jeep safari in to the Nagarhole National Park.
                          Overnight stay at Kabini.
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/india6.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>06</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/india7.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>07</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">MYSORE</a>
                        </h3>

                        <p>
                          After breakfast checkout from the resort and drive to
                          Mysore. Mysore, the erstwhile capital of former
                          princely state and the second largest city in
                          Karnataka played an important role in the history of
                          South India, a city of palaces and gardens and is
                          famous for its silk, sandalwood and jasmine. Arrive at
                          Mysore and check in to the hotel. Overnight stay at
                          Mysore.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">MYSORE</a>
                        </h3>

                        <p>
                          Start your day with a sightseeing tour of Mysore.
                          Major attractions in Mysore is the Mysore Palace,
                          located in the city centre is said to be the most
                          magnificent and captivating. Religiously significant
                          Chamundeshwari Temple situated atop of hill Chamundi
                          displays Dravidian influence in some of its
                          architecture. It is quadrangular in shape, and there
                          is a statue of the Lord Ganesha on the doorway.
                          Evening free to explore the colorful bazaars.
                          Overnight stay at Mysore.
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/india8.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>08</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/india9.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>09</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">BANGALORE</a>
                        </h3>

                        <p>
                          Check out from the hotel and drive to Bangalore. This
                          capital city of Karnataka state is rightfully
                          proclaimed as the Silicone Valley of India because it
                          is here that the Indian IT industry has matured to
                          such an extent that it is now in great demand
                          overseas. Bangalore is a beautiful city with parks and
                          gardens, which is why the city is called the Garden
                          city of India. Bangalore often referred to as the
                          ‘Garden City' is known for its numerous parks and
                          avenues. Arrive at Bangalore and check in to the
                          hotel. Major attractions in Bangalore are; Tipu
                          Sultan's Palace, Bull Temple, Lalbagh Gardens, Cubbon
                          Park & Museum and Vidhan Soudha. Hider Ali laid out
                          the garden with tropical plants and shrubs in the
                          Lalbagh botanical gardens. In the centre lies a large
                          iron and glass conservatory modeled on the lines of
                          the Crystal Palace, London. Vidhana Soudha, a huge
                          neo- Dravidian granite building which houses the
                          secretariat and the state legislative. It is one of
                          the most impressive modern buildings in India. The
                          huge door to the entrance of the cabinet room is made
                          of pure sandalwood. Visit to the Bull Temple where the
                          monolithic deity - Nandi, draws large devotees from
                          all over the country. Overnight stay in Bangalore.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <a href="toursingle-1.html">BANGALORE</a>
                        </h3>

                        <p>
                          Check out from the hotel and drive to Bangalore
                          airport. Board the flight to your onward destination
                          feeling contented about a wonderful holiday you spent
                          with us. Our services end after dropping you at
                          Bangalore airport.
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <a
                          href="toursingle-1.html"
                          style={{
                            backgroundImage: "url(images/india10.webp)",
                          }}
                        ></a>
                        <div class="b-date">
                          <a href="#" class="white">
                            <strong>10</strong> Day
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-xs-12">
              <div class="list-sidebar">
                <div class="sidebar-item">
                  <form class="filter-box" onSubmit={sendEmail}>
                    <h3 class="white">Enquire This Package</h3>
                    <div class="row">
                      <div class="col-xs-12">
                        <div class="form-group">
                          <label class="white">Your Name</label>
                          <div class="input-box">
                            <i class="flaticon-user"></i>
                            <input
                              type="text"
                              placeholder="Name"
                              style={{ paddingRight: "6px" }}
                              name="name"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label class="white">Check In</label>
                          <div class="input-box">
                            <i class="flaticon-calendar"></i>
                            <input
                              id="date-range0"
                              type="text"
                              placeholder="yyyy-mmm-dd"
                              name="datein"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label class="white">Check Out</label>
                          <div class="input-box">
                            <i class="flaticon-calendar"></i>
                            <input
                              id="date-range1"
                              type="text"
                              placeholder="yyyy-mm-dd"
                              name="dateout"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label class="white">Adult</label>
                          <div class="input-box">
                            <i class="flaticon-add-user"></i>
                            <select class="niceSelect">
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xs-12">
                        <div class="form-group">
                          <label class="white">Children</label>
                          <div class="input-box">
                            <i class="flaticon-add-user"></i>
                            <select class="niceSelect">
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="comment-btn text-right mar-top-15">
                        <input
                          type="submit"
                          class="biz-btn"
                          id="submit"
                          value="Enquire Now"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div class="sidebar-item">
                  <h3>Services</h3>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>24/7 Reception</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Parking</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Bar</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Restaurant</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Satellite Television</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse mar-bottom-15">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Lift/ELevator</label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state p-warning-o">
                      <label>Luggage Storage </label>
                    </div>
                  </div>
                </div>

                <div class="sidebar-item">
                  <h3>Star Rating</h3>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        <div class="star-rating">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        <div class="star-rating">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        <div class="star-rating">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        <div class="star-rating">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star checked"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        <div class="star-rating">
                          <span class="fa fa-star checked"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="sidebar-item">
                  <h3>City</h3>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        Amsterdam<span class="number">749</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" checked />
                    <div class="state">
                      <label>
                        Rotterdam<span class="number">630</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        Copenghan<span class="number">58</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        New Delhi<span class="number">29</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        New York<span class="number">29</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        Kathmandu<span class="number">29</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        Brisbane<span class="number">29</span>
                      </label>
                    </div>
                  </div>
                  <div class="pretty p-default p-thick p-pulse">
                    <input type="checkbox" />
                    <div class="state">
                      <label>
                        Tokyo<span class="number">29</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
