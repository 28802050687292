import React from "react";
import { NavLink } from "react-router-dom";

export const TopDeals = () => {
  return (
    <div>
      <section class="top-deals" id="findmore">
        <div class="container">
          <div class="section-title">
            <h2>Educational Tours</h2>
            <p>
              Want to make learning fun for children? Arrange for an educational
              tour.
            </p>
          </div>
          <div class="row top-deal-slider">
            <div class="col-md-4 slider-item">
              <div class="slider-image">
                <img src="images/nasaffffff.jpg" alt="image" />
              </div>
              <div class="slider-content">
                <h6 class="mar-bottom-10">
                  <i class="fa fa-map-marker-alt"></i> NASA
                </h6>
                <h4>
                  <NavLink to="/education1">NASA educational tour</NavLink>
                </h4>
                <p>With upto 30% Off</p>
              </div>
            </div>
            <div class="col-md-4 slider-item">
              <div class="slider-image">
                <img src="images/USA.webp" alt="image" />
              </div>
              <div class="slider-content">
                <h6 class="mar-bottom-10">
                  <i class="fa fa-map-marker-alt"></i> USA
                </h6>
                <h4>
                  <NavLink to="/education2">Fantastic Panoramic USA</NavLink>
                </h4>
                <p>With upto 30% Off</p>
              </div>
            </div>
            <div class="col-md-4 slider-item">
              <div class="slider-image">
                <img src="images/caneda.webp" alt="image" />
              </div>
              <div class="slider-content">
                <h6 class="mar-bottom-10">
                  <i class="fa fa-map-marker-alt"></i> Canada
                </h6>
                <h4>
                  <NavLink to="/education3">Exciting Canadian Rockies</NavLink>
                </h4>
                <p>With upto 30% Off</p>
              </div>
            </div>
            <div class="col-md-4 slider-item">
              <div class="slider-image">
                <img src="images/china.webp" alt="image" />
              </div>
              <div class="slider-content">
                <h6 class="mar-bottom-10">
                  <i class="fa fa-map-marker-alt"></i> China, Beijing, Shanghai
                </h6>
                <h4>
                  <NavLink to="/education4">
                    Go Crazy in China Holiday Package
                  </NavLink>
                </h4>
                <p>With upto 30% Off</p>
              </div>
            </div>
            <div class="col-md-4 slider-item">
              <div class="slider-image">
                <img src="images/london.webp" alt="image" />
              </div>
              <div class="slider-content">
                <h6 class="mar-bottom-10">
                  <i class="fa fa-map-marker-alt"></i> London, Paris
                </h6>
                <h4>
                  <NavLink to="/education5">Splendors of Europe</NavLink>
                </h4>
                <p>With upto 30% Off</p>
              </div>
            </div>
            <div class="col-md-4 slider-item">
              <div class="slider-image">
                <img src="images/srilanka001.webp" alt="image" />
              </div>
              <div class="slider-content">
                <h6 class="mar-bottom-10">
                  <i class="fa fa-map-marker-alt"></i>Disneyland
                </h6>
                <h4>
                  <NavLink to="/education6">Exotic Disneyland</NavLink>
                </h4>
                <p>With upto 30% Off</p>
              </div>
            </div>
            <div class="col-md-4 slider-item">
              <div class="slider-image">
                <img src="images/disneycover.jpg" alt="image" />
              </div>
              <div class="slider-content">
                <h6 class="mar-bottom-10">
                  <i class="fa fa-map-marker-alt"></i>France, Italy, Swiss
                </h6>
                <h4>
                  <NavLink to="/education7">Best Views of Switzerland</NavLink>
                </h4>
                <p>With upto 30% Off</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
