import React from "react";
import { NavLink } from "react-router-dom";

export const StudyAbrod = () => {
  return (
    <>
      <section class="blogmain blog-fullwidth">
        <div class="container">
          <div class="row">
            <div class="col-md-10 col-md-offset-1">
              <div class="blog-list">
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-4 col-sm-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <NavLink
                          to="/studyabroad"
                          style={{
                            backgroundImage: "url(images/germanystudy.webp)",
                          }}
                        ></NavLink>
                      </div>
                    </div>
                    <div class="col-md-8 col-sm-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <NavLink to="/studyabroad">Germany</NavLink>
                        </h3>

                        <div class="description mar-bottom-30">
                          <ul class="expect">
                            <li>
                              <i class="fa fa-circle"></i>
                              Lowest tuitions fees
                            </li>
                            <li>
                              <i class="fa fa-circle"></i> Safe country to live
                              and study
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>
                              17,500 degree programmes offered
                            </li>
                            <li>
                              <i class="fa fa-circle"></i> Affordable living
                              expenses
                            </li>
                            <li>
                              <i class="fa fa-circle"></i> High class degrees
                              recognised around the world
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>
                              Most of the German universities are public
                              institutions, and students have traditionally fee
                              payment.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>
                              Germany is the most populous member state in the
                              European Union. After the United States, it is
                              second most popular migration destination in
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>
                              There are 400 higher education institutions across
                              the country with more than 14,000 Bachelor and
                              Master degree programmes.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>
                              Many universities offer international courses in
                              English language as well. So knowing German to
                              study in Germany is not mandatory.Students are
                              allowed to stay for a year after study, in the
                              event of looking for a job. Germany offer
                              long-term work opportunities for students.{" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-8 col-sm-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <NavLink to="/studyabroad">Canada</NavLink>
                        </h3>

                        <div class="description mar-bottom-30">
                          <ul class="expect">
                            <li>
                              <i class="fa fa-circle"></i>
                              Canada has the eleventh largest economy in the
                              world and one of the world's wealthiest nations,
                              and is a member of the Organization for Economic
                              Co-operation and Development and Group of Eight .
                              As with other developed nations, the Canadian
                              economy is dominated by the service industry.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i> In Canada you can
                              work 20 hours weekly during your study and on
                              vacation can work full time. This will give you an
                              additional source to support your studies and
                              obtain valuable work experience with a foreign
                              employer.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>
                              If you study any program of minimum 1 year
                              duration, your dependent can accompany you on
                              Spouse Work Permit and can work full-time during
                              your study and vacation.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i> Strong growing
                              economy with large manpower shortage
                            </li>
                            <li>
                              <i class="fa fa-circle"></i> Canadian education
                              system consists of both public and private
                              schools, Universities & Colleges, Community
                              Colleges ,Technical Institutes, Career Colleges,
                              Language Schools and Secondary Schools.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>
                              Canada is a very safe country. Political unrest is
                              limited in Canada and crime rates are low and
                              Canada is Secular
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <NavLink
                          to="/studyabroad"
                          style={{
                            backgroundImage: "url(images/canadastudy.webp)",
                          }}
                        ></NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-4 col-sm-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <NavLink
                          to="/studyabroad"
                          style={{
                            backgroundImage: "url(images/usastudy.webp)",
                          }}
                        ></NavLink>
                      </div>
                    </div>
                    <div class="col-md-8 col-sm-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <NavLink to="/studyabroad">USA</NavLink>
                        </h3>
                        {/* <li>
                          <b></b>USA has the world’s largest international
                          student population, with nearly 590,000 students
                          choosing to broaden their education and life
                          experience in the United States. Nearly 5% of all
                          students enrolled in higher-level education are
                          international students, and the numbers are growing.
                          From the mid-1950’s, when international student
                          enrollment was only just reaching 35,000,
                          international education in the USA has come a long
                          way.
                        </li>
                        <li>
                          <b></b>The United States of America has been a global
                          leader in the field of education, setting standards
                          for the quality of faculty, research and the generic
                          experience a student is opened up to when studying
                          overseas
                        </li>
                        <li>
                          <b></b>Options to apply for graduate assistantship,
                          Teaching assistantship and research assistantship
                        </li>
                        <li>
                          <b></b>Masters students spouse can accompany on F1
                          dependent visa and can work full time.
                        </li>
                        <li>
                          <b></b>The best approach of the USA education system
                          is that it allows one to learn and earn. This not only
                          gives one, a thorough knowledge of the subjects, but
                          also teaches one to have a practical & professional
                          approach. It gives better growth prospects worldwide.
                        </li>
                        <li>
                          <b></b>U.S. universities offer a diverse choice of
                          academic, cultural and athletic activities to choose
                          from which not only enrich the educational experience
                          but also help students make new friends and become
                          global citizens.
                        </li> */}
                        <div class="description mar-bottom-30">
                          <ul class="expect">
                            <li>
                              <i class="fa fa-circle"></i>
                              USA has the world’s largest international student
                              population, with nearly 590,000 students choosing
                              to broaden their education and life experience in
                              the United States. Nearly 5% of all students
                              enrolled in higher-level education are
                              international students, and the numbers are
                              growing. From the mid-1950’s, when international
                              student enrollment was only just reaching 35,000,
                              international education in the USA has come a long
                              way.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i> The United States of
                              America has been a global leader in the field of
                              education, setting standards for the quality of
                              faculty, research and the generic experience a
                              student is opened up to when studying overseas
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>
                              Options to apply for graduate assistantship,
                              Teaching assistantship and research assistantship
                            </li>
                            <li>
                              <i class="fa fa-circle"></i> Masters students
                              spouse can accompany on F1 dependent visa and can
                              work full time.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i> The best approach of
                              the USA education system is that it allows one to
                              learn and earn. This not only gives one, a
                              thorough knowledge of the subjects, but also
                              teaches one to have a practical & professional
                              approach. It gives better growth prospects
                              worldwide.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>
                              U.S. universities offer a diverse choice of
                              academic, cultural and athletic activities to
                              choose from which not only enrich the educational
                              experience but also help students make new friends
                              and become global citizens.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-8 col-sm-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <NavLink to="/studyabroad">
                            United Kingdom (UK)
                          </NavLink>
                        </h3>

                        <div class="description mar-bottom-30">
                          <ul class="expect">
                            <li>
                              <i class="fa fa-circle"></i>
                              British Education has long attracted and welcomed
                              high caliber students of different nationalities
                              and backgrounds, and today builds on hundreds of
                              years of experience in providing quality education
                              to students. To ensure that the quality is
                              maintained, Britain has implemented unrivalled
                              quality assurance and academic audit systems.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i> Britain has long been
                              a popular destination for Indian students. With
                              more than 140 institutes of higher education to
                              choose from, all equipped with extensive
                              facilities, Britain is able to offer a broad
                              spectrum of subjects from the highly academic to
                              the purely practical in anything from architecture
                              to zoology.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>
                              International education hub for more than 100
                              years & Easy Visa rules for international students
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>No permission required
                              from the job center for International students
                              studying at UK institutions to undertake spare
                              time or part time work
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>Shorter duration of
                              study and affordable education. Due to shorter
                              duration students can save on both tuition fees
                              and living costs and also gets a head start on
                              working life as they will graduate earlier
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>
                              England is quite a unique place that offers a rich
                              multicultural atmosphere. It is a home to some of
                              the famous and most prestigious universities of
                              the world , excellent infrastructure and standards
                              of teaching that makes you ready for a successful
                              career ahead.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <NavLink
                          to="/studyabroad"
                          style={{
                            backgroundImage: "url(images/uk.webp)",
                          }}
                        ></NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-4 col-sm-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <NavLink
                          to="/studyabroad"
                          style={{
                            backgroundImage: "url(images/Swedenstudy.webp)",
                          }}
                        ></NavLink>
                      </div>
                    </div>
                    <div class="col-md-8 col-sm-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <NavLink to="/studyabroad">Sweden</NavLink>
                        </h3>

                        <div class="description mar-bottom-30">
                          <ul class="expect">
                            <li>
                              <i class="fa fa-circle"></i>
                              The capital city of Sweden is Stockholm, is also
                              the country’s largest city. Other large cities are
                              Gothenburg, in western Sweden and Malmö . Uppsala
                              and Lund are well-known university cities. Sweden
                              as a country is known for its inventions and
                              universities with academic excellence that help
                              students build a great career.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>Beautiful cities,
                              efficient public transportation, peaceful country
                              with minimum pollution
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>
                              Many of successful innovative companies like
                              Volvo, Scania, Ericsson, Tetrapak Ikea, H&M,
                              Eletrolux
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>Many Swedish
                              multinational organizations have English as their
                              corporate language, and a large number of
                              university degree programmes and courses are
                              taught in English.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>Most of the
                              universities are fully subsidize tuition costs for
                              students, regardless of their nationality , making
                              education quite affordable
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>
                              Masters programmes are offered in close
                              collaborate with local industry and institutions
                              are obliged to interact with the local community
                              while specializing in specific subject areas.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-8 col-sm-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <NavLink to="/studyabroad">Australia</NavLink>
                        </h3>

                        <div class="description mar-bottom-30">
                          <ul class="expect">
                            <li>
                              <i class="fa fa-circle"></i>
                              Australia officially is a single continent
                              country. The Australian federation comprises six
                              states and two main territories. Australia is the
                              world's 12th-largest economy
                            </li>
                            <li>
                              <i class="fa fa-circle"></i> If you study minimum
                              2 year Certificate, Diploma, Advanced Diploma or
                              Associate Degree courses leading to Trade in
                              skilled occupation demand list and obtained IELTS
                              6 in general version you can apply for 18 months
                              temporary residence visa and if the spouse has
                              accompanied, even they can apply for extension
                              Visa.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>
                              Research focused & technology driven education and
                              Attractive scholarships and bursaries
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>Options for married
                              students to take their spouses with fulltime work
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>To study in Australia,
                              there is no specific age criteria. You can enroll
                              for any course in Australia, if you meet entry
                              criteria irrespective of your education
                              background.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>
                              Vocational institutions, both government and
                              private funded closely linked with various
                              industries offering highly practical and
                              skill-based courses.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>
                              Compare to India, Australia has low population,
                              but the country is double the size. Australia is
                              having many growing industries and number of
                              multinational companies also represents Australia.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <NavLink
                          to="/studyabroad"
                          style={{
                            backgroundImage: "url(images/Australiastudy.webp)",
                          }}
                        ></NavLink>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-4 col-sm-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <NavLink
                          to="/studyabroad"
                          style={{
                            backgroundImage: "url(images/NewZealandstudy.webp)",
                          }}
                        ></NavLink>
                      </div>
                    </div>
                    <div class="col-md-8 col-sm-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <NavLink to="/studyabroad">New Zealand</NavLink>
                        </h3>

                        <div class="description mar-bottom-30">
                          <ul class="expect">
                            <li>
                              <i class="fa fa-circle"></i>
                              New Zealand is made up of two main islands and
                              many smaller ones in the Pacific Ocean. Being in
                              the mid-latitudes and surrounded by the sea,
                              climate is temperate with warm summers and cool
                              winters.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>World’s most
                              recognized education system & Qualifications
                              widely recognized and respected internationally
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>
                              On Successful Completion of Post graduate Diploma,
                              Bachelor degrees, Masters’ Degree, and Doctoral
                              Programs you will get 3 Years Job Search Visa.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>Large numbers of
                              Indians has migrated in the last 10 years and have
                              been successful.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>It is an
                              English-speaking country and by living and
                              studying in New Zealand you can improve your
                              English proficiency. Many New Zealand institutions
                              offer English courses with many intakes throughout
                              the year to enhance your English language skill.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>
                              New Zealand offers lower annual tuition fees for
                              many popular courses at universities and
                              institutions. Bachelor degrees can also be
                              completed in only three years (exceptions include
                              degrees in Engineering and medicine) compared with
                              four years in America and Australia.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-8 col-sm-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <NavLink to="/studyabroad">
                            MBBS in Philippines
                          </NavLink>
                        </h3>

                        <div class="description mar-bottom-30">
                          <ul class="expect">
                            <li>
                              <i class="fa fa-circle"></i>
                              The Philippines is an English-speaking,Friendly
                              and safe country
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>
                              High level of learning experience
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>
                              Affiliation with the best hospitals & Clinics
                            </li>
                            <li>
                              <i class="fa fa-circle"></i> Excellent hostel
                              accommodation and Indian food are available at
                              reasonable cost.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>
                              Philippines is considered the gate way to USA, UK
                              and Canada
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <NavLink
                          to="/studyabroad"
                          style={{
                            backgroundImage: "url(images/Australiastudy.webp)",
                          }}
                        ></NavLink>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div class="blog-full mar-bottom-30">
                  <div class="row">
                    <div class="col-md-8 col-sm-8 col-xs-12">
                      <div class="blog-content">
                        <h3 class="blog-title">
                          <NavLink to="/studyabroad">
                            {" "}
                            MBBS in Philippines
                          </NavLink>
                        </h3>

                        <div class="description mar-bottom-30">
                          <ul class="expect">
                            <li>
                              <i class="fa fa-circle"></i>
                              The Philippines is an English-speaking,Friendly
                              and safe country.Philippines is the third biggest
                              English talking nation in the world also one of
                              the better medical education systems in Asia.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i> High level of
                              learning experience
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>
                              Affiliation with the best hospitals & Clinics
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>Excellent hostel
                              accommodation and Indian food are available at
                              reasonable cost.
                            </li>
                            <li>
                              <i class="fa fa-circle"></i>Philippines is
                              considered the gate way to USA, UK and Canada
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 blog-height">
                      <div class="blog-image">
                        <NavLink
                          to="/studyabroad"
                          style={{
                            backgroundImage: "url(images/phili.jpg)",
                          }}
                        ></NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
