import React from "react";

import emailjs from "emailjs-com";
import swal from "sweetalert";
export const AboutChoose = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "template_58obn2m",
        e.target,
        "user_aKCieJwSXAcLTXl3NIl4R"
      )
      .then(
        (result) => {
          swal({
            title: "Done!",
            text: "We will get back to you shortly!",
            icon: "success",
            timer: 3000,
            button: false,
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }
  return (
    <div>
      <section class="banner-form form-style1">
        <div class="container">
          <div class="section-title">
            <h2 class="white">
              <span>ASK</span> YOUR BEST PLAN
            </h2>
            <p class="white">
              Find your best tour package for spending your vacation
            </p>
          </div>
          <div class="form-outer">
            <form onSubmit={sendEmail}>
              <div class="form-content">
                <div class="tab-content">
                  <div id="travel" class="tab-pane in active">
                    <div class="row filter-box">
                      <div class="col-md-3 col-sm-12 col-xs-12">
                        <div class="form-group">
                          <label class="white">Your Name</label>
                          <div class="input-box">
                            <i class="flaticon-user"></i>
                            <input
                              type="text"
                              placeholder="Name"
                              style={{ paddingRight: "6px" }}
                              name="name"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6 col-xs-12">
                        <div class="form-group">
                          <label>Check In</label>
                          <div class="input-box">
                            <i class="flaticon-calendar"></i>
                            <input
                              id="date-range0"
                              type="text"
                              placeholder="yyyy-mm-dd"
                              name="datein"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6 col-xs-12">
                        <div class="form-group">
                          <label>Check Out</label>
                          <div class="input-box">
                            <i class="flaticon-calendar"></i>
                            <input
                              id="date-range1"
                              type="text"
                              placeholder="yyyy-mm-dd"
                              name="dateout"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6 col-xs-12">
                        <div class="form-group">
                          <label>Person</label>
                          <div class="input-box">
                            <i class="flaticon-add-user"></i>
                            <select class="niceSelect">
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="comment-btn text-right mar-top-15">
                        <input
                          type="submit"
                          class="biz-btn"
                          id="submit"
                          value="Enquire Now"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <br></br>
    </div>
  );
};
