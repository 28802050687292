import React from "react";

export const Terms = () => {
  return (
    <div>
      <section class="breadcrumb-outer text-center">
        <div class="container">
          <div class="breadcrumb-content">
            <h2 style={{ color: "black" }}>Terms & Conditions </h2>
            <nav aria-label="breadcrumb">
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/" style={{ color: "black" }}>
                    Home
                  </a>
                </li>
                <li
                  class="breadcrumb-item active"
                  aria-current="page"
                  style={{ color: "black" }}
                >
                  Terms & Conditions
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="overlay"></div>
      </section>
      <section class="terms">
        <div class="container">
          <div class="content">
            <div class="terms-service">
              <h4>Terms of Service</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                in mollis tellus. In consectetur venenatis dui, eu lacinia diam
                vehicula vel. Nunc tempor tortor arcu, maximus pretium enim
                ultrices vel. Cras dignissim porttitor commodo. Curabitur
                eleifend, magna a dapibus laoreet, lacus mi suscipit tellus,
                gravida cursus quam arcu sed sapien. Duis nec finibus nisi. Nunc
                id metus eu nisi pharetra molestie at et magna. Vivamus rhoncus
                orci at mi sagittis, in aliquet tortor porttitor. Curabitur
                bibendum leo ut orci convallis viverra. Curabitur iaculis lorem
                vitae aliquam condimentum. Pellentesque tristique accumsan
                pellentesque. Cras blandit massa in ligula laoreet, a
                ullamcorper ex viverra. Vestibulum at eleifend erat.
                Pellentesque justo est, lacinia in imperdiet eget, finibus
                posuere dui.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                in mollis tellus. In consectetur venenatis dui, eu lacinia diam
                vehicula vel. Nunc tempor tortor arcu, maximus pretium enim
                ultrices vel. Cras dignissim porttitor commodo. Curabitur
                eleifend, magna a dapibus laoreet, lacus mi suscipit tellus,
                gravida cursus quam arcu sed sapien. Duis nec finibus nisi. Nunc
                id metus eu nisi pharetra molestie at et magna.
              </p>
            </div>
            <div class="terms-cookies">
              <h4>Use of Cookies</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                in mollis tellus. In consectetur venenatis dui, eu lacinia diam
                vehicula vel. Nunc tempor tortor arcu, maximus pretium enim
                ultrices vel. Cras dignissim porttitor commodo. Curabitur
                eleifend, magna a dapibus laoreet, lacus mi suscipit tellus,
                gravida cursus quam arcu sed sapien. Duis nec finibus nisi. Nunc
                id metus eu nisi pharetra molestie at et magna. Vivamus rhoncus
                orci at mi sagittis, in aliquet tortor porttitor. Curabitur
                bibendum leo ut orci convallis viverra.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                in mollis tellus. In consectetur venenatis dui, eu lacinia diam
                vehicula vel. Nunc tempor tortor arcu, maximus pretium enim
                ultrices vel. Cras dignissim porttitor commodo. Curabitur
                eleifend, magna a dapibus laoreet, lacus mi suscipit tellus,
                gravida cursus quam arcu sed sapien. Duis nec finibus nisi. Nunc
                id metus eu nisi pharetra molestie at et magna.
              </p>
            </div>
            <div class="terms-site">
              <h4>Use of Site</h4>
              <div class="site-ownership">
                <h5>General/Ownership</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  in mollis tellus. In consectetur venenatis dui, eu lacinia
                  diam vehicula vel. Nunc tempor tortor arcu, maximus pretium
                  enim ultrices vel. Cras dignissim porttitor commodo. Curabitur
                  eleifend, magna a dapibus laoreet, lacus mi suscipit tellus,
                  gravida cursus quam arcu sed sapien. Duis nec finibus nisi.
                  Nunc id metus eu nisi pharetra molestie at et magna. Vivamus
                  rhoncus orci at mi sagittis, in aliquet tortor porttitor.
                  Curabitur bibendum leo ut orci convallis viverra. Curabitur
                  iaculis lorem vitae aliquam condimentum. Pellentesque
                  tristique accumsan pellentesque. Cras blandit massa in ligula
                  laoreet, a ullamcorper ex viverra. Vestibulum at eleifend
                  erat. Pellentesque justo est, lacinia in imperdiet eget,
                  finibus posuere dui.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  in mollis tellus. In consectetur venenatis dui, eu lacinia
                  diam vehicula vel. Nunc tempor tortor arcu, maximus pretium
                  enim ultrices vel. Cras dignissim porttitor commodo. Curabitur
                  eleifend, magna a dapibus laoreet, lacus mi suscipit tellus,
                  gravida cursus quam arcu sed sapien. Duis nec finibus nisi.
                  Nunc id metus eu nisi pharetra molestie at et magna.
                </p>
              </div>
              <div class="site-copyright">
                <h5>Copyright</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  in mollis tellus. In consectetur venenatis dui, eu lacinia
                  diam vehicula vel. Nunc tempor tortor arcu, maximus pretium
                  enim ultrices vel. Cras dignissim porttitor commodo. Curabitur
                  eleifend, magna a dapibus laoreet, lacus mi suscipit tellus,
                  gravida cursus quam arcu sed sapien. Duis nec finibus nisi.
                  Nunc id metus eu nisi pharetra molestie at et magna. Vivamus
                  rhoncus orci at mi sagittis, in aliquet tortor porttitor.
                  Curabitur bibendum leo ut orci convallis viverra.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  in mollis tellus. In consectetur venenatis dui, eu lacinia
                  diam vehicula vel. Nunc tempor tortor arcu, maximus pretium
                  enim ultrices vel. Cras dignissim porttitor commodo. Curabitur
                  eleifend, magna a dapibus laoreet, lacus mi suscipit tellus,
                  gravida cursus quam arcu sed sapien. Duis nec finibus nisi.
                  Nunc id metus eu nisi pharetra molestie at et magna.
                </p>
              </div>
              <div class="site-notice">
                <h5>
                  Notice and procedures for making claims of copyright
                  infringement
                </h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  in mollis tellus. In consectetur venenatis dui, eu lacinia
                  diam vehicula vel. Nunc tempor tortor arcu, maximus pretium
                  enim ultrices vel. Cras dignissim porttitor commodo. Curabitur
                  eleifend, magna a dapibus laoreet, lacus mi suscipit tellus,
                  gravida cursus quam arcu sed sapien. Duis nec finibus nisi.
                  Nunc id metus eu nisi pharetra molestie at et magna. Vivamus
                  rhoncus orci at mi sagittis, in aliquet tortor porttitor.
                  Curabitur bibendum leo ut orci convallis viverra.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  in mollis tellus. In consectetur venenatis dui, eu lacinia
                  diam vehicula vel. Nunc tempor tortor arcu, maximus pretium
                  enim ultrices vel. Cras dignissim porttitor commodo. Curabitur
                  eleifend, magna a dapibus laoreet, lacus mi suscipit tellus,
                  gravida cursus quam arcu sed sapien. Duis nec finibus nisi.
                  Nunc id metus eu nisi pharetra molestie at et magna.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
