import React from "react";
import { Nav } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const Header = () => {
  const location = useLocation();

  return (
    <>
      <header className="main_header_area">
        <div className="header-content">
          <div className="container">
            <div className="links links-left">
              <ul>
                <li>
                  <i
                    className="fa fa-phone-alt"
                    style={{ color: "red", fontSize: "25px" }}
                  ></i>
                  &nbsp;&nbsp;
                  <a href="tel:+91 8921843284">+91 8921843284</a>
                </li>
                <li>
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&to= info@flightsworld.in &su=SUBJECT&body=BODY"
                    target="_blank" rel="noreferrer"
                  >
                    <i className="fa fa-envelope-open"></i>{" "}
                    <span
                      className="__cf_email__"
                      data-cfemail="472e29212807092237263e2633352e6924282a"
                    >
                      info@flights-world.com
                    </span>
                  </a>
                </li>
                {/* <li>
                  <select>
                    <option>EUR</option>
                    <option>FRA</option>
                    <option>ESP</option>
                  </select>
                </li> */}
              </ul>
            </div>
            <div className="links links-right pull-right">
              <ul>
                <li>
                  <ul className="social-links">
                    <li>
                      <a
                        href="https://www.facebook.com/www.flightsworld.in/"
                        target="_blank"
                      >
                        <i className="fab fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/flights_world/"
                        target="_blank"
                      >
                        <i className="fab fa-instagram" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <a href="#" data-toggle="modal" data-target="#login">
                    <i className="fa fa-sign-in-alt"></i> Login
                  </a>
                </li>
                <li>
                  <a href="#" data-toggle="modal" data-target="#register">
                    <i className="fa fa-sign-out-alt"></i> Register
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>

        <div className="header_menu affix-top">
          <nav className="navbar navbar-default">
            <div className="container">
              <div className="navbar-flex">
                <div className="navbar-header">
                  <a className="navbar-brand" href="/">
                    {location.pathname === "/studyabroad" ? (
                      <img
                        src="images/fwslogo.gif"
                        alt="fws"
                        style={{ width: "196px" }}
                      />
                    ) : (
                      <img
                        src="images/flightsworldlogo.gif"
                        alt="flightsworld"
                        style={{ width: "277px" }}
                      />
                    )}
                  </a>
                </div>

                <div
                  className="collapse navbar-collapse"
                  id="bs-example-navbar-collapse-1"
                >
                  <ul className="nav navbar-nav" id="responsive-menu">
                    <li>
                      <a href="/">Home</a>
                    </li>

                    <li className="submenu dropdown">
                      <NavLink to="/">
                        <ScrollLink
                          exact
                          to="flights"
                          spy={true}
                          smooth={true}
                          duration={200}
                          offset={-250}
                          classNameName="nav-link btn__appointment"
                        >
                          Flights{" "}
                        </ScrollLink>
                      </NavLink>
                    </li>
                    <li className="submenu dropdown">
                      <NavLink to="/">
                        <ScrollLink
                          exact
                          to="dealsandoffers"
                          spy={true}
                          smooth={true}
                          duration={200}
                          offset={-200}
                          classNameName="nav-link btn__appointment"
                        >
                          Deals and Offers{" "}
                        </ScrollLink>
                      </NavLink>
                    </li>
                    <li className="submenu dropdown">
                      <NavLink exact to="/aboutus">
                        About US{" "}
                      </NavLink>
                    </li>
                    <li className="submenu dropdown">
                      <NavLink to="/contactus">Contact </NavLink>
                    </li>
                    <li className="btn__cta navbar-nav">
                      <NavLink to="/studyabroad">Study Abroad</NavLink>
                    </li>
                  </ul>
                </div>
                <div id="slicknav-mobile"></div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
