import React from "react";

export const Education6 = () => {
  return (
    <div>
      <section class="breadcrumb-outer text-center">
        <div class="container">
          <div class="breadcrumb-content">
            <h2 style={{ color: "black" }}>Explore</h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
        </div>
        <div class="overlay"></div>
      </section>

      <section class="flight-destinations bg-white">
        <div class="container">
          <div class="row">
            <div id="content" class="col-md-8 col-xs-12">
              <div class="detail-content content-wrapper">
                <div class="detail-info">
                  <div class="detail-info-content clearfix">
                    <h4 class="mar-0">Exotic Disneyland </h4>
                    <h2>Disneyland</h2>
                    <div class="rating">
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                    </div>
                    {/* <h3 class=" detail-price mar-0 text-right">
                      $659 <br />
                      Person
                    </h3> */}
                  </div>
                </div>
                <div class="gallery detail-box about-slider">
                  <div class="gallery-item">
                    <img src="images/disney.jpg" alt="in_th_030_01_sm" />
                  </div>
                </div>
                <div class="description flight-table mar-bottom-30">
                  <div class="detail-title">
                    <h3>Itinerary</h3>
                  </div>
                  <div class="description mar-bottom-30">
                    <ul class="expect">
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 1:</b> Paris -On arriving at the Paris airport,
                        meet our representative who’ll escort you to one of our
                        listed hotels. Check-in at the hotel. Spend the day
                        relaxing in the comfortable surroundings of the hotel
                        room. In the evening you may take a leisurely walk to
                        explore Paris, the romantic capital of the world.
                        Experience an unforgettable evening by attending the
                        Lido Show, the famous French cabaret located in the
                        Champs Elysee.Later return to the hotel for dinner and
                        overnight stay.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i> <b>Day 2:</b>Paris:
                        Panoramic City Tour- On the following day after
                        breakfast, embark on a sightseeing tour of Paris. The
                        tour will start with a visit to some of the beautiful
                        Napoleonic era structures followed by a visit to the
                        Champs eleysees. This vast lawn features a wide walking
                        path and the borders of the park are lined with a
                        variety of trees and flowering shrubs. Eiffel Tower, one
                        of the most famous icons of Paris is a prominent
                        attraction to be watched. It is famed to be the tallest
                        building present in Paris. Later in the day you may pay
                        a visit to the Musee de Louvre, originally a royal
                        fortress which was converted into the museum which
                        houses more 35,000 works of art.
                        <br />
                        Later return to the hotel for dinner and overnight stay.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 3:</b> Disneyland-The 3rd day of the tour is
                        reserved for a full day excursion to Disneyland. The day
                        is sure to be full of fun, entertainment and adventure.
                        There are numerous rides present in the Disney Park, to
                        make you feel the adrenaline rush under your skin. Also
                        enjoy a visit to the beautiful Walt Disney Studio Park
                        where you can enjoy the sight of various stunt shows and
                        also enjoy watching a film being shot.
                        <br />
                        Enjoy dinner and overnight stay at the hotel.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i> <b>Day 4:</b>Paris-Post
                        breakfast today the day is free for leisure activities.
                        You may move out to explore Paris on your own.
                        <br />
                        Enjoy dinner and overnight stay at the hotel
                      </li>
                      <li>
                        <i class="fa fa-circle"></i> <b>Day 5:</b>Depart
                        Paris-In the morning after breakfast, get transferred to
                        Paris CDG airport on SIC basis to board a flight for
                        your onward destination.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div id="sidebar" class="col-md-4 col-xs-12">
              <aside class="detail-sidebar sidebar-wrapper">
                <div class="sidebar-item">
                  <form class="filter-box">
                    <h3 class="white">For Enquiry</h3>
                    <div class="row">
                      <div class="col-xs-12">
                        <div class="form-group">
                          <label class="white">Your Name</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Name"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="white">Your Email</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Email"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="white">Your Phone</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Phone"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-xs-12">
                        <div class="form-group mar-top-15">
                          <a href="#" class="biz-btn">
                            Submit
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="sidebar-item">
                  <div class="detail-title">
                    <h3>Most Visited</h3>
                  </div>
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/dis1.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/dis2.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/dis3.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
