import React from "react";
import emailjs from "emailjs-com";
import swal from "sweetalert";

export const ContactUs = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "template_dodttdu",
        e.target,
        "user_aKCieJwSXAcLTXl3NIl4R"
      )
      .then(
        (result) => {
          swal({
            title: "Done!",
            text: "We will get back to you shortly!",
            icon: "success",
            timer: 3000,
            button: false,
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }
  return (
    <div>
      <section class="breadcrumb-outer text-center">
        <div class="container">
          <div class="breadcrumb-content">
            <h2 style={{ color: "black" }}>Contact Us </h2>
            <nav aria-label="breadcrumb">
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/" style={{ color: "black" }}>
                    Home
                  </a>
                </li>
                <li
                  class="breadcrumb-item active"
                  aria-current="page"
                  style={{ color: "black" }}
                >
                  Contact Us
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="overlay"></div>
      </section>

      <section class="contact-main pad-top-0 contact1 bg-white">
        <div class="container">
          <div class="contact-support mar-bottom-30">
            <div class="row">
              <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="support-item">
                  <h3>Customer Support</h3>
                  <p>
                    Fabulous Travel worry free knowing that we're here if you
                    need us, 24 hours a day
                  </p>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="support-item">
                  <h3>Technical Support</h3>
                  <p>
                    24*7 Tech Support Is One Of The Best Online Travel Support
                    Services In the Leading Generation
                  </p>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12">
                <div class="support-item">
                  <h3>Booking Queries</h3>
                  <p>
                    Call or Message To Flights World and See All The Essential
                    Information
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="contact-info">
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="contact-info">
                  <div class="info-item mar-bottom-30">
                    <div class="info-icon">
                      <i class="fa fa-map-marker"></i>
                    </div>
                    <div class="info-content">
                      <p>FLIGHTS WORLD TRAVEL AND TOURS</p>

                      <p> KAWDIAR,TRIVANDRUM</p>
                    </div>
                  </div>
                  <div class="info-item info-item-or mar-bottom-30">
                    <div class="info-icon">
                      <a href="tel:+91 8921843284">
                        <i class="fa fa-phone"></i>
                      </a>
                    </div>
                    <div class="info-content">
                      <a href="tel:+91 8921843284">
                        {" "}
                        <p> +91 8921843284</p>
                      </a>
                    </div>
                  </div>
                  <div class="info-item mar-bottom-30">
                    <div class="info-icon">
                      <a href="https://mail.google.com/mail/?view=cm&fs=1&to= info@flights-world.com &su=SUBJECT&body=BODY">
                        {" "}
                        <i class="fa fa-envelope"></i>
                      </a>
                    </div>
                    <div class="info-content">
                      <p>
                        <a
                          href="https://mail.google.com/mail/?view=cm&fs=1&to= info@flights-world.com &su=SUBJECT&body=BODY"
                          class="__cf_email__"
                          data-cfemail="1b72757d745b557e6b7a627a6f697235787476"
                        >
                          info@flights-world.com
                        </a>
                      </p>
                      {/* <p>
                        <a
                          href="https://cyclonethemes.com/cdn-cgi/l/email-protection"
                          class="__cf_email__"
                          data-cfemail="660e030a1626280316071f0712140f4805090b"
                        >
                          [email&#160;protected]
                        </a>
                      </p> */}
                    </div>
                  </div>
                  <div class="info-item mar-bottom-30">
                    <div class="info-icon">
                      <a href="https://flights-world.com/#/">
                        {" "}
                        <i class="fa fa-globe"></i>
                      </a>
                    </div>
                    <div class="info-content">
                      <a href="www.flights-world.com">
                        {" "}
                        <p>www.flights-world.com</p>
                      </a>
                      {/* <p>www.flightsworld.in</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div id="contact-form1" class="contact-form">
                  <h3>Keep in Touch</h3>
                  <div id="contactform-error-msg"></div>
                  <form onSubmit={sendEmail}>
                    <div class="form-group">
                      <input
                        type="text"
                        name="name"
                        class="form-control"
                        id="fname"
                        placeholder="Name"
                        required
                      />
                    </div>
                    {/* <div class="form-group">
                      <input
                        type="text"
                        name="last_name"
                        class="form-control"
                        id="lname"
                        placeholder="Last Name"
                      />
                    </div> */}
                    <div class="form-group">
                      <input
                        type="email"
                        name="email"
                        class="form-control"
                        id="email"
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        name="phone"
                        class="form-control"
                        id="phnumber"
                        pattern="^\+?\d{0,13}"
                        placeholder="Phone"
                        required
                      />
                    </div>
                    <div class="textarea">
                      <textarea
                        name="comments"
                        placeholder="Enter a message"
                      ></textarea>
                    </div>
                    <div class="comment-btn text-right mar-top-15">
                      <input
                        type="submit"
                        class="biz-btn"
                        id="submit"
                        value="Send Message"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
