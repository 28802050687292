import React from "react";

export const Education2 = () => {
  return (
    <div>
      <section class="breadcrumb-outer text-center">
        <div class="container">
          <div class="breadcrumb-content">
            <h2 style={{ color: "black" }}>Explore</h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
        </div>
        <div class="overlay"></div>
      </section>

      <section class="flight-destinations bg-white">
        <div class="container">
          <div class="row">
            <div id="content" class="col-md-8 col-xs-12">
              <div class="detail-content content-wrapper">
                <div class="detail-info">
                  <div class="detail-info-content clearfix">
                    <h4 class="mar-0">Fantastic Panoramic USA</h4>
                    <h2>USA</h2>
                    <div class="rating">
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                    </div>
                    {/* <h3 class=" detail-price mar-0 text-right">
                      $659 <br />
                      Person
                    </h3> */}
                  </div>
                </div>
                <div class="gallery detail-box about-slider">
                  <div class="gallery-item">
                    <img src="images/USA.webp" alt="in_th_030_01_sm" />
                  </div>
                </div>
                <div class="description flight-table mar-bottom-30">
                  <div class="detail-title">
                    <h3>Itinerary</h3>
                  </div>
                  <div class="description mar-bottom-30">
                    <ul class="expect">
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 1:</b> After your arrival at New York Universal
                        Airport, you will be welcome to "New York," the "City
                        That Never Sleeps"—New York is a city of superlatives.
                        It is the most energizing, business and social capitals;
                        the country's trailblazer. For a century, it has been
                        one of the world's significant focuses of business and
                        money. Upon landing in the airplane terminal, you will
                        be exchanged to the lodging.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i> <b>Day 2:</b>After morning
                        breakfast , continue for a City voyage through New York.
                        The touring will incorporate USA's acclaimed point of
                        interest "Statue of Liberty". We will take a ship ride
                        to Liberty Island for a staggering perspective of the
                        New York City Skyline. Next, we drive through Wall
                        Street, Times Square, Ground Zero, Central Park,
                        Rockefeller Center, United Nations and Trump. Later in
                        the day, we encounter the perspective of a lifetime from
                        the 86th carpet of the Empire State Building.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 3:</b>After morning breakfast, move to Washington
                        DC, the capital of the United States of America. Other
                        than the central government, tourism is DC's greatest
                        industry. The city pulls in very nearly twenty million
                        guests every year. Check into the hotel and spend time
                        at relaxation.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i> <b>Day 4:</b>After morning
                        breakfast, explore the capital city of the US. Visit the
                        White House, the Capitol Building, Supreme Court,
                        Lincoln Memorial, the Smithsonian Air and Space
                        historical center, Union Station, Pentagon building,
                        World War II Memorial Building, and Washington landmark.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i> <b>Day 5:</b>After morning
                        breakfast, depart to Niagara the town of the world
                        celebrated falls, which structures one of the
                        characteristic marvels of the World. Visit the
                        fashionable boat "Servant of the Mist" which takes us
                        near the thundering falls and around the American and
                        Horseshoe Falls (occasional). Come back to the lodging
                        to spruce up after you’re soggy. You can additionally
                        visit the falls on own during the evening to see them
                        lit up flawlessly.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 6:</b>Depart to Orlando by flight. Orlando is the
                        sixth biggest city of Florida and is additionally known
                        to be its biggest inland city. The vicinity of Disney
                        World amusement park has made this city a well-known
                        excursion spot. This city is said to welcome more than
                        52 million travelers consistently. On arrival, move to
                        hotel.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 7:</b> Today, you will be visiting the Magic
                        Kingdom, which includes 107 sections of land, opened on
                        October 1, 1971. It's been named "The Most Magical Place
                        on Earth!" and is home to seven themed terrains, and
                        many attractions that speak to all ages. It is the spot
                        where storybook dream is ordinary reality, and Disney
                        classics are brought to life from the minute you step
                        onto Main Street USA, you are transported to a spot
                        where the forethoughts of the outside world appear to
                        mystically melt away.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 8:</b> Start your day at the Epcot focus where
                        you can touch the future and venture to the far corners
                        of the planet with an astounding cluster of attractions
                        and live exhibitions. This is a region of 260 sections
                        of land dedicated to past accomplishments and the
                        eventual fate of engineering. World showcase offers a
                        sort of perpetual World Fair, with structures committed
                        to eleven nations.
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 9:</b> Today, you will visit the world's biggest
                        Marine Park, Sea World - Here you should find numerous
                        attractions. We challenge you to take a unique water
                        liner rush ride through the baffling lost city of
                        Atlantis. Take off through risk on a plane copter ride
                        to the Wild Arctic. At Sea World, touch, encourage or
                        get eye to eye with magnificent and astonishing wild
                        creatures and get in on all the activity that moves you
                        back over and over!
                      </li>
                      <li>
                        <i class="fa fa-circle"></i>
                        <b>Day 10:</b> On the last day after breakfast, move to
                        Orlando airport to catch a flight to India via New York
                        with sweet memories of USA.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div id="sidebar" class="col-md-4 col-xs-12">
              <aside class="detail-sidebar sidebar-wrapper">
                <div class="sidebar-item">
                  <form class="filter-box">
                    <h3 class="white">For Enquiry</h3>
                    <div class="row">
                      <div class="col-xs-12">
                        <div class="form-group">
                          <label class="white">Your Name</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Name"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="white">Your Email</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Email"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="white">Your Phone</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Phone"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-xs-12">
                        <div class="form-group mar-top-15">
                          <a href="#" class="biz-btn">
                            Submit
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="sidebar-item">
                  <div class="detail-title">
                    <h3>Most Visited</h3>
                  </div>
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/usa11.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/usa12.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/usa13.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
