import React from "react";

export const Privacy = () => {
  return (
    <div>
      <section class="breadcrumb-outer text-center">
        <div class="container">
          <div class="breadcrumb-content">
            <h2 style={{ color: "black" }}>Privacy Policy</h2>
            <nav aria-label="breadcrumb">
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/" style={{ color: "black" }}>
                    Home
                  </a>
                </li>
                <li
                  class="breadcrumb-item active"
                  aria-current="page"
                  style={{ color: "black" }}
                >
                  Privacy Policy
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="overlay"></div>
      </section>
      <section class="terms">
        <div class="container">
          <div class="content">
            <div class="terms-service">
              <h4> Privacy Policy</h4>
              <p>
                Please read these Terms and Conditions of Use carefully before
                using or purchasing any materials, goods or services from our
                websites. Links to and usage of these websites shall be subject
                to approval of the terms and conditions set out below ('Terms'),
                which shall include our Privacy Policy. By viewing, using or
                receiving any materials, goods or services from these websites,
                you agree to be bound by these terms and conditions. If you do
                not agree with any of these terms and conditions, you cannot use
                the Platform. We reserve the right at any time, to amend these
                terms and conditions. The modified terms shall take effect
                immediately when these Terms and Conditions are revised. Your
                continued use of the Platform following the posting of updates
                to the Terms and Conditions would mean that you support these
                changes. Please return to this page on a regular basis to check
                any updates. We invite you to review and familiarise yourself
                with our Privacy Policy, but you should know that we do not sell
                or rent personal information from our customers to third
                parties.Please note that we review our Privacy Policy from time
                to time and may make periodic adjustments to the policy in
                accordance with that review. Therefore, you might want to
                bookmark this page and/or regularly check this page to make sure
                you have the latest edition. Irrespective of potential changes,
                we will abide by the privacy policies outlined in this Privacy
                Policy when you provide us with your personal information.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
