import React from "react";
import { Link, NavLink } from "react-router-dom";
export const NotFound = () => {
  return (
    <div>
      <section class="error">
        <div class="container">
          <div class="error-content text-center">
            <div class="error-container">
              <span class="four">
                <span class="screen-reader-text">4</span>
              </span>
              <span class="zero">
                <span class="screen-reader-text">0</span>
              </span>
              <span class="four">
                <span class="screen-reader-text">4</span>
              </span>
            </div>
            <p class="mar-0">
              Sorry the page you tried cannot be found. You may have typed the
              address incorrectly or you may have used an outdated link.Please
              visit the links below instead.
            </p>
            <div class="error-btn">
              <a href="/" class="biz-btn biz-btn1 mar-right-10">
                GO TO HOMEPAGE
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
