import React from "react";

export const Country1 = () => {
  return (
    <>
      <section class="breadcrumb-outer text-center">
        <div class="container">
          <div class="breadcrumb-content">
            <h2 style={{ color: "black" }}>Explore</h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
        </div>
        <div class="overlay"></div>
      </section>

      <section class="flight-destinations bg-white">
        <div class="container">
          <div class="row">
            <div id="content" class="col-md-8 col-xs-12">
              <div class="detail-content content-wrapper">
                <div class="detail-info">
                  <div class="detail-info-content clearfix">
                    <h4 class="mar-0">Explore</h4>
                    <h2>Sri Lanka</h2>
                    <div class="rating">
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                    </div>
                    {/* <h3 class=" detail-price mar-0 text-right">
                      $659 <br />
                      Person
                    </h3> */}
                  </div>
                </div>
                <div class="gallery detail-box about-slider">
                  <div class="gallery-item">
                    <img src="images/srilanka.webp" alt="in_th_030_01_sm" />
                  </div>
                </div>
                <div class="description flight-table mar-bottom-30">
                  <div class="detail-title">
                    <h3>Overview</h3>
                  </div>
                  <div class="description-content">
                    <p>
                      Sri Lanka was once one of the richest countries in Asia.
                      In recent times it has not developed at the dramatic pace
                      of its neighbours’ in the region adding to its charm and
                      character. The relative lack of development and gentle
                      island pace make Sri Lanka the perfect destination for
                      those looking fora varied and stimulating holiday. Sri
                      Lanka was once one of the richest countries in Asia. In
                      recent times it has not developed at the dramatic pace of
                      its neighbours’ in the region adding to its charm and
                      character. The relative lack of development and gentle
                      island pace make Sri Lanka the perfect destination for
                      those looking for a varied and stimulating holiday.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div id="sidebar" class="col-md-4 col-xs-12">
              <aside class="detail-sidebar sidebar-wrapper">
                <div class="sidebar-item">
                  <form class="filter-box">
                    <h3 class="white">For Enquiry</h3>
                    <div class="row">
                      <div class="col-xs-12">
                        <div class="form-group">
                          <label class="white">Your Name</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Name"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="white">Your Email</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Email"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="white">Your Phone</label>
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Phone"
                              style={{ paddingLeft: "3px" }}
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-xs-12">
                        <div class="form-group mar-top-15">
                          <a href="#" class="biz-btn">
                            Submit
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="sidebar-item">
                  <div class="detail-title">
                    <h3>Most Popular In Srilenka</h3>
                  </div>
                  <div class="sidebar-content about-slider">
                    <div class="sidebar-package">
                      <div class="sidebar-package-image">
                        <img src="images/sri13.jpg" alt="Images" />
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
